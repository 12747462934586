<template>
  <MyNavbar />
  <div class="listNav" @click="toggleNav">
    <div class="d-flex">
      <font-awesome-icon :icon="['fas', 'list']" />
      <span class="d-block px-2">القائمة</span>
    </div>
  </div>
  <MyHeader />
  <div class="container pt-5">
    <table class="table">
      <thead>
        <tr>
          <th><input type="checkbox" checked /></th>
          <th>رقم الزبون</th>
          <th>الحالة</th>
          <th>الإجمالي</th>
          <th>العمولة</th>
          <th>الطلب</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><input type="checkbox" /></td>
          <td>N857875</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>تم الطلب</td>
        </tr>
        <tr>
          <td><input type="checkbox" /></td>
          <td>N857875</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>تم الطلب</td>
        </tr>
        <tr>
          <td><input type="checkbox" /></td>
          <td>N857875</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>تم الطلب</td>
        </tr>
      </tbody>
    </table>
    <div class="transfer-section">
      <div>
        <label for="transfer-amount">مبلغ التحويل</label>
        <input type="text" id="transfer-amount" />
      </div>
      <button class="btn d-block">طلب تحويل</button>
    </div>
  </div>
  <div class="container pt-5">
    <h2>عمليات التحويل</h2>
    <table>
      <thead>
        <tr>
          <th>رقم الزبون</th>
          <th>الحالة</th>
          <th>الإجمالي</th>
          <th>العمولة</th>
          <th>تاريخ التحويل</th>
          <th>القائم بالتحويل</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>2-2-2022</td>
          <td>Admin</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>2-2-2022</td>
          <td>Admin</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>2-2-2022</td>
          <td>Admin</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Collected</td>
          <td>271</td>
          <td>500</td>
          <td>2-2-2022</td>
          <td>Admin</td>
        </tr>
      </tbody>
    </table>
  </div>
  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
import MyHeader from "@/components/MyHeader.vue";
export default {
  name: "CommissionTransferRequests",
  components: {
    MyNavbar,
    MyFooter,
    MyHeader,
  },
  data() {
    return {
      isNavVisible: true,
      totalOrders: [],
    };
  },
  methods: {
    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
    },
    async getTotalOrders() {
      try {
        const token = localStorage.getItem("authToken"); // Assuming you store your auth token in localStorage

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/affiliate/sights",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          // Handle the case where the response status code is not 2xx
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 0) {
          console.error("Unauthenticated: ", data.msg);
        } else if (data && Array.isArray(data.data)) {
          this.totalOrders = data.data;
        } else {
          console.error("Unexpected response structure", data);
        }
      } catch (error) {
        console.error("Failed to fetch total orders", error);
      }
    },
  },
  async mounted() {
    this.getTotalOrders();
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.navbar .nav-item {
  font-weight: bold;
}
.listNav {
  padding: 10px;
  background-color: #0c2d58;
  text-align: right;
  width: 40%;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: none;
}
.dashboard-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.form-control {
  border-radius: 5px;
}
.search-btn {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.search-btn:hover {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.inputSearsh {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
}
.divCont {
  height: 200px;
  margin: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.divCont h4:after {
  content: "";
  background-color: #fd6735;
  width: 60px;
  height: 4px;
  display: block;
  margin: 25px auto;
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    right: 0px;
    top: 27%;
    width: 40%;
    background-color: #0c2d58 !important;
  }
  .navbar .nav-item {
    text-align: right;
    width: 100%;
    color: #fdfdfd;
    margin: 5px 0;
    padding: 10px;
  }
  .listNav {
    display: block;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th,
table td {
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
}

table th {
  background-color: #f4f4f4;
  color: #e94e0f;
}

table td {
  color: #333;
}

input[type="checkbox"] {
  transform: scale(1.2);
}

.transfer-section {
  margin-top: 20px;
}

.transfer-section label {
  font-size: 1.2rem;
  margin-right: 10px;
  display: block;
}

.transfer-section input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 250px;
  margin: 5px 10px;
}

.transfer-section button {
  padding: 10px 50px;
  background-color: #001f4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 7px 10px;
}

.transfer-section button:hover {
  background-color: #003080;
}
</style>
