<template>
  <MyNavbar />
  <div class="slider">
    <!-- Carousel Code -->
  </div>
  <div class="container my-5">
    <div class="row">
      <div
        class="col-md-3"
        v-for="(ordcount, index) in totalOrders"
        :key="index"
      >
        <div class="divCont">
          <h4 class="pt-5">{{ ordcount.st_ar }}</h4>
          <h5>{{ ordcount.count }}</h5>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="bg-light p-3 my-3">
    <div class="container">
      <h2>عمولاتك</h2>
      <p>
        خليك عضو اساسي من كيان بيهتم بيك وبشغلك ويسعي دايما بأنه يقدملك افضل
        خدمه تقدر من خلالها تبني البراند الخاص بيك وتزود ارباحك من خلال تيم قوي
        بيحاول دايما ان يقدم افضل ما يمتلك خليك عضو اساسي من كيان بيهتم بيك
        وبشغلك ويسعي دايما بأنه يقدملك افضل خدمه تقدر من خلالها تبني البراند
        يمتلك
      </p>

      <div class="d-flex">
        <div class="timeline-end">
          <p>بونص ربح:</p>
          <p class="colorBlue">الوردات التي تم تسليمها</p>
        </div>
        <div class="timeline">
          <div class="timeline-item">
            <div class="label">1500 جنية</div>
            <div class="value">35 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">1250 جنية</div>
            <div class="value">30 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">1000 جنية</div>
            <div class="value">25 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">750 جنية</div>
            <div class="value">20 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">600 جنية</div>
            <div class="value">15 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">500 جنية</div>
            <div class="value">10 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">125 جنية</div>
            <div class="value">5 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">100 جنية</div>
            <div class="value">4 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">75 جنية</div>
            <div class="value">2 أوردر</div>
          </div>
          <div class="timeline-item">
            <div class="label">50 جنية</div>
            <div class="value">1 أوردر</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="secPrroductInHome mt-5">
    <div class="container">
      <div class="d-flex justify-content-between mb-3">
        <h3>الأكثر مبيعا</h3>
        <a
          class="colorOrange"
          @click="showAllProductResently = !showAllProductResently"
        >
          {{ showAllProductResently ? "عرض أقل" : "عرض الكل" }}
        </a>
      </div>
      <div class="row py-2">
        <div
          class="col-md-3"
          v-for="product in displayedRecentlyArrived"
          :key="product.id"
        >
          <div class="DivProduct border">
            <div class="productImage">
              <img :src="product.photo" alt="notFound" />
            </div>
            <div class="contentProduct">
              <p>{{ product.name }}</p>
              <div class="d-flex justify-content-between">
                <span class="colorOrange d-flex">
                  <span>السعر :</span>
                  <span>{{ product.purchasing_price }}</span>
                </span>
                <span class="colorOrange d-flex">
                  <span>العمولة :</span>
                  <span>{{ product.commission }}</span>
                </span>
              </div>
              <small class="pt-2">
                <small class="pt-2 d-flex">
                  <span>متوفر في المخزون :</span>
                  <span class="mx-1">{{ product.total_stock }}</span>
                </small>
              </small>
              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-primary px-3"
                  @click="addToFavorites(product.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'cart-shopping']"
                  />
                  {{ favoriteButtonText[product.id] || "أضف الى السلة" }}
                </button>

                <button
                  class="btn border btnBorderRadius"
                  @click="toggleFavorite(product.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'heart']"
                    :class="{ activeHeart: isFavorite(product.id) }"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="secPrroductInHome my-5">
    <div class="container">
      <div class="d-flex justify-content-between mb-3">
        <h3>أحدث المنتجات</h3>
        <a class="colorOrange" @click="showAllProducts = !showAllProducts">
          {{ showAllProducts ? "عرض أقل" : "عرض الكل" }}
        </a>
      </div>
      <div class="row py-2">
        <div
          class="col-md-3"
          v-for="product in displayedProducts"
          :key="product.id"
        >
          <div class="DivProduct border">
            <div class="productImage">
              <img :src="product.photo" alt="notFound" />
            </div>
            <div class="contentProduct">
              <p style="text-align: justify">{{ product.name }}</p>
              <div class="d-flex justify-content-between">
                <span class="colorOrange d-flex">
                  <span>السعر :</span>
                  <span class="mx-1">{{ product.purchasing_price }}</span>
                </span>
                <span class="colorOrange d-flex">
                  <span>العمولة :</span>
                  <span class="mx-1">{{ product.commission }}</span>
                </span>
              </div>
              <small class="pt-2 d-flex">
                <span>متوفر في المخزون :</span>
                <span class="mx-1">{{ product.total_stock }}</span>
              </small>
              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-primary px-3"
                  @click="addToFavorites(product.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'cart-shopping']"
                  />
                  {{ favoriteButtonText[product.id] || "أضف الى السلة" }}
                </button>

                <button
                  class="btn border btnBorderRadius"
                  @click="toggleFavorite(product.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'heart']"
                    :class="{ activeHeart: isFavorite(product.id) }"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MyFooter />
</template>

<script>
import MyFooter from "@/components/footer.vue";
import MyNavbar from "@/components/Navbar.vue";
export default {
  name: "_Navbar",
  components: {
    MyNavbar,
    MyFooter,
  },
  data() {
    return {
      isActiveHeart: false,
      products: [], // Full list of products
      productsresntly: [],
      showAllProducts: false, // To control the display of all products
      showAllProductResently: false, // To control the display of all products
      favoriteProducts: [], // List of favorite product IDs
      favoriteButtonText: {},
      totalOrders: [],
    };
  },
  computed: {
    displayedProducts() {
      // Return either a limited number of products or all products
      return this.showAllProducts ? this.products : this.products.slice(0, 4);
    },
    displayedRecentlyArrived() {
      return this.showAllProductResently
        ? this.productsresntly
        : this.productsresntly.slice(0, 4);
    },
  },
  methods: {
    async toggleFavorite(product_id) {
      const isCurrentlyFavorite = this.isFavorite(product_id);

      try {
        const url = isCurrentlyFavorite
          ? `https://ecomerg.za3bot.com/api/add/favorite`
          : `https://ecomerg.za3bot.com/api/add/favorite`;

        const token = localStorage.getItem("authToken"); // Retrieve the token

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the header
          },
          body: JSON.stringify({ product_id: product_id }),
        });

        if (response.ok) {
          if (isCurrentlyFavorite) {
            this.favoriteProducts = this.favoriteProducts.filter(
              (id) => id !== product_id
            );
          } else {
            this.favoriteProducts.push(product_id);
          }
        } else {
          console.error("Failed to toggle favorite status");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async addToFavorites(product_id) {
      try {
        this.favoriteButtonText[product_id] = "جارٍ الإضافة...";

        const token = localStorage.getItem("authToken"); // Retrieve the token

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(
          `https://ecomerg.za3bot.com/api/add/favorite`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the header
            },
            body: JSON.stringify({ product_id: product_id }),
          }
        );

        if (response.ok) {
          this.favoriteButtonText[product_id] = "تمت الإضافة";
        } else {
          this.favoriteButtonText[product_id] = "أضف الى السلة";
          console.error("Failed to add to favorites");
        }
      } catch (error) {
        this.favoriteButtonText[product_id] = "أضف الى السلة";
        console.error("Error:", error);
      }
    },

    isFavorite(product_id) {
      // Check if the product is in the list of favorite products
      return this.favoriteProducts.includes(product_id);
    },

    async getProducts() {
      try {
        const response = await fetch("https://ecomerg.za3bot.com/api/home");
        const data = await response.json();
        this.products = data.data.best_selling;
        this.productsresntly = data.data.recently_arrived;

        // Populate the initial favoriteProducts array from the data
        // Assuming `favorite` is a field indicating if the product is a favorite
        this.favoriteProducts = this.products
          .filter((product) => product.favorite)
          .map((product) => product.id);
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    },
    async getTotalOrders() {
      try {
        const token = localStorage.getItem("authToken"); // Assuming you store your auth token in localStorage

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/affiliate/sights",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          // Handle the case where the response status code is not 2xx
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 0) {
          console.error("Unauthenticated: ", data.msg);
        } else if (data && Array.isArray(data.data)) {
          this.totalOrders = data.data;
        } else {
          console.error("Unexpected response structure", data);
        }
      } catch (error) {
        console.error("Failed to fetch total orders", error);
      }
    },
  },

  async mounted() {
    await this.getProducts();
    this.getTotalOrders();
  },
};
</script>

<style scoped>
.slider .carousel-item {
  width: 100%;
  height: 450px;
}
.divCont {
  height: 200px;
  margin: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  text-align: center;
}
.divCont h4:after {
  content: "";
  background-color: #fd6735;
  width: 60px;
  height: 4px;
  display: block;
  margin: 25px auto;
}
.bg-light h2 {
  text-align: center;
  margin: 10px 0px;
}
.bg-light p {
  line-height: 2.4;
  font-weight: bold;
  text-align: justify;
}
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1200px;
  padding: 0 20px;
  position: relative;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: #ccc;
  z-index: 1;
  transform: translateY(-50%);
}
.timeline-item {
  position: relative;
  text-align: center;
  z-index: 2;
  /* background: #fff; */
  padding: 2 10px;
  flex: 1;
  width: 92px;
}
.timeline-item:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 0 auto;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
.label {
  color: #ff6200;
  font-weight: bold;
  font-size: 14px;
}
.value {
  margin-top: 15px;
  font-weight: bold;
  color: #0c2d58;
  font-size: 14px;
}
.timeline-end {
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
  color: #ff6200;
  font-size: 18px;
}
.timeline-end span {
  font-weight: normal;
  color: #333;
  font-size: 14px;
}

.secPrroductInHome a {
  text-decoration: none;
  font-weight: bold;
}
.DivProduct {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}
.DivProduct .productImage {
  width: 100%;
  height: 60%;
}
.DivProduct .productImage img {
  width: 100%;
  height: 100%;
}
.contentProduct {
  padding: 5px;
}
.contentProduct p {
  color: #0c2d58;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.contentProduct span {
  font-weight: bold;
  text-align: justify;
  display: block;
  font-size: 14px;
}
.contentProduct small {
  display: block;
  color: #565656;
  text-align: justify;
}
.btnBorderRadius {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeHeart {
  color: red; /* Change this to the color you want when active */
}

font-awesome-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .timeline {
    flex-direction: column;
    padding: 0;
  }
  .timeline:before {
    display: none;
  }
  .timeline-item {
    margin-bottom: 20px;
    max-width: none;
  }
  .timeline-item:before {
    top: 50%;
    /* left: -14px; */
    left: 75%;
    transform: translateX(0) translateY(-50%);
  }
  .timeline-item .label,
  .timeline-item .value {
    display: block;
    text-align: right;
  }
}
.timeline-end p {
  font-size: 14px;
}
</style>
