<template>
  <MyNavbar />
  <div class="navbar bg-light">
    <div class="nav-item">
      <select v-model="selectedCategory" class="btn btnselect">
        <option value="" selected>اختر القسم</option>
        <option value="ملابس رجالي">ملابس رجالي</option>
        <option value="ملابس نسائية">ملابس نسائية</option>
        <option value="احذية رجالي">احذية رجالي</option>
        <option value="احذية نسائية">احذية نسائية</option>
      </select>
    </div>
    <div class="nav-item">
      <select v-model="selectedVendor" class="btn btnselect">
        <option value="" selected>اختر البائع</option>
        <option value="زارا">زارا</option>
        <option value="سبورت">سبورت</option>
        <option value="اديداس">اديداس</option>
      </select>
    </div>
    <div class="nav-item">
      <select v-model="selectedPriceOrder" class="btn btnselect">
        <option value="">تنصيف حسب السعر</option>
        <option value="high">الاعلى</option>
        <option value="low">الاقل</option>
      </select>
    </div>
    <div class="nav-item">
      <select v-model="selectedCommissionOrder" class="btn btnselect">
        <option value="">تنصيف حسب العمولة</option>
        <option value="high">الاعلى</option>
        <option value="low">الاقل</option>
      </select>
    </div>
    <div class="nav-item">
      <span class="nav-icon"
        ><font-awesome-icon :icon="['fas', 'download']" class="mx-2"
      /></span>
      تصدير
    </div>
    <div class="nav-item" @click="toggleFilter">
      <span class="nav-icon"
        ><font-awesome-icon :icon="['fas', 'table-columns']" class="mx-2"
      /></span>
      تصفية
    </div>
  </div>

  <div class="secPrroductInHome mt-5">
    <div class="container">
      <div class="row py-2">
        <div
          :class="{ 'col-md-3': !isFiltered, 'col-md-6': isFiltered }"
          v-for="product in paginatedProducts"
          :key="product.id"
        >
          <div class="DivProduct border">
            <div class="productImage">
              <img :src="product.photo" alt="notFound" />
            </div>
            <div class="contentProduct">
              <p>{{ product.name }}</p>
              <div class="d-flex justify-content-between">
                <span class="colorOrange d-flex">
                  <span>السعر :</span>
                  <span>{{ product.purchasing_price }}</span>
                </span>
                <span class="colorOrange d-flex">
                  <span>العمولة :</span>
                  <span>{{ product.commission }}</span>
                </span>
              </div>
              <small class="pt-2">
                <small class="pt-2 d-flex">
                  <span>متوفر في المخزون :</span>
                  <span class="mx-1">{{ product.total_stock }}</span>
                </small>
              </small>
              <div class="d-flex justify-content-between mt-3">
                <button
                  class="btn btn-primary px-3"
                  :disabled="isAddingToFavorite(product.id)"
                  @click="addToFavorites(product.id)"
                >
                  <font-awesome-icon
                    class="text-white"
                    :icon="['fas', 'cart-shopping']"
                  />
                  {{ favoriteButtonText[product.id] || "أضف الى السلة" }}
                </button>

                <button
                  class="btn border btnBorderRadius"
                  @click="toggleFavorite(product.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'heart']"
                    :class="{ activeHeart: isFavorite(product.id) }"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Pagination Controls -->
  <div class="pagination-container m-auto w-50 text-center">
    <button
      class="pagination-button btn btn-primary"
      :disabled="currentPage === 1"
      @click="prevPage"
    >
      السابق
    </button>

    <button
      v-for="page in totalPages"
      :key="page"
      :class="[
        'pagination-button btn border-primary mx-1',
        { active: currentPage === page },
      ]"
      @click="changePage(page)"
    >
      {{ page }}
    </button>

    <button
      class="pagination-button btn btn-primary"
      :disabled="currentPage === totalPages"
      @click="nextPage"
    >
      التالي
    </button>
  </div>

  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";

export default {
  name: "_products",
  components: {
    MyNavbar,
    MyFooter,
  },
  data() {
    return {
      products: [],
      selectedCategory: "",
      selectedVendor: "",
      selectedPriceOrder: "",
      selectedCommissionOrder: "",
      favoriteProducts: [],
      favoriteButtonText: {},
      addingToFavoriteIds: [], // Tracks adding to favorite process
      token: null, // To store the token
      isFiltered: false,
      currentPage: 1, // Current active page
      pageSize: 4, // Number of products per page
    };
  },
  computed: {
    filteredProducts() {
      let filtered = this.products;

      // Filter by category
      if (this.selectedCategory) {
        filtered = filtered.filter(
          (product) => product.category === this.selectedCategory
        );
      }

      // Filter by vendor
      if (this.selectedVendor) {
        filtered = filtered.filter(
          (product) => product.vendor === this.selectedVendor
        );
      }

      // Sort by price
      if (this.selectedPriceOrder) {
        filtered = filtered.sort((a, b) =>
          this.selectedPriceOrder === "high"
            ? b.purchasing_price - a.purchasing_price
            : a.purchasing_price - b.purchasing_price
        );
      }

      // Sort by commission
      if (this.selectedCommissionOrder) {
        filtered = filtered.sort((a, b) =>
          this.selectedCommissionOrder === "high"
            ? b.commission - a.commission
            : a.commission - b.commission
        );
      }

      return filtered;
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.pageSize);
    },
    // This computed property returns only the products for the current page
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredProducts.slice(start, end);
    },
  },
  methods: {
    // Retrieve the token from localStorage and store it
    loadToken() {
      this.token = localStorage.getItem("authToken");
    },
    toggleFilter() {
      this.isFiltered = !this.isFiltered;
    },
    async toggleFavorite(product_id) {
      if (!product_id) {
        console.error("Product ID is required");
        return;
      }

      // Check if the product is currently a favorite
      const isCurrentlyFavorite = this.isFavorite(product_id);

      try {
        const url = isCurrentlyFavorite
          ? `https://ecomerg.za3bot.com/api/add/favorite`
          : `https://ecomerg.za3bot.com/api/add/favorite`;

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`, // Include token in headers
          },
          body: JSON.stringify({ product_id: product_id }), // Pass the product_id in the body
        });

        if (response.ok) {
          // Toggle the favorite status
          if (isCurrentlyFavorite) {
            this.favoriteProducts = this.favoriteProducts.filter(
              (id) => id !== product_id
            );
          } else {
            this.favoriteProducts.push(product_id);
          }
        } else {
          console.error("Failed to toggle favorite status");
          console.error("Response status:", response.status);
          const errorData = await response.json();
          console.error("Error response data:", errorData);
        }
      } catch (error) {
        console.error("Error while toggling favorite:", error);
      }
    },

    isFavorite(product_id) {
      return this.favoriteProducts.includes(product_id);
      // console.log("ccc", this.favoriteProducts.includes(product_id));
    },

    isAddingToFavorite(product_id) {
      return this.addingToFavoriteIds.includes(product_id);
    },

    async addToFavorites(product_id) {
      if (!product_id) {
        console.error("Product ID is required");
        return;
      }

      // Prevent multiple clicks for the same product
      if (this.isAddingToFavorite(product_id)) return;

      // Add product to adding to favorite list
      this.addingToFavoriteIds.push(product_id);

      try {
        this.favoriteButtonText[product_id] = "جارٍ الإضافة...";
        console.log(
          "Sending request to add favorite with product ID:",
          product_id
        );

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/add/favorite",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`, // Include token in headers
            },
            body: JSON.stringify({ product_id: product_id }), // Pass the product_id in the body
          }
        );

        if (response.ok) {
          this.favoriteButtonText[product_id] = "تمت الإضافة";
        } else {
          console.error("Failed to add to favorites");
          console.error("Response status:", response.status);
          const errorData = await response.json();
          console.error("Error response data:", errorData);
          this.favoriteButtonText[product_id] = "أضف الى السلة";
        }
      } catch (error) {
        console.error("Error while adding to favorites:", error);
        this.favoriteButtonText[product_id] = "أضف الى السلة";
      } finally {
        // Remove product from adding to favorite list
        this.addingToFavoriteIds = this.addingToFavoriteIds.filter(
          (id) => id !== product_id
        );
      }
    },
    changePage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        window.scrollTo(0, 0); // Scroll to top when page changes
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        window.scrollTo(0, 0);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        window.scrollTo(0, 0);
      }
    },
    async getProducts() {
      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/view/products",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`, // Include token in headers
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("The response is not JSON");
        }

        const data = await response.json();
        this.products = data.data.products;

        // Track which products are favorite
        this.favoriteProducts = this.products
          .filter((product) => product.favorite)
          .map((product) => product.id);
        console.log("ccc", this.products);
      } catch (error) {
        console.error("Failed to fetch products:", error.message);
      }
    },
  },
  watch: {
    // Reset to first page when filters change
    selectedCategory() {
      this.currentPage = 1;
    },
    selectedVendor() {
      this.currentPage = 1;
    },
    selectedPriceOrder() {
      this.currentPage = 1;
    },
    selectedCommissionOrder() {
      this.currentPage = 1;
    },
  },
  async mounted() {
    this.loadToken(); // Load the token when the component is mounted
    await this.getProducts(); // Fetch products with the token
    console.log("ccc", this.products.id);
  },
};
</script>

<style scoped>
.secPrroductInHome a {
  text-decoration: none;
  font-weight: bold;
}
.DivProduct {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}
.DivProduct .productImage {
  width: 100%;
  height: 60%;
}
.DivProduct .productImage img {
  width: 100%;
  height: 100%;
}
.contentProduct {
  padding: 5px;
}
.contentProduct p {
  color: #0c2d58;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.contentProduct span {
  font-weight: bold;
  text-align: justify;
  display: block;
  font-size: 14px;
}
.contentProduct small {
  display: block;
  color: #565656;
  text-align: justify;
}
.btnBorderRadius {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeHeart {
  color: red; /* Change this to the color you want when active */
}

font-awesome-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}
.navbar {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.nav-item {
  color: #000;
  text-decoration: none;
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-icon {
  margin-right: 5px;
}

.nav-item:hover {
  color: #ff6200; /* Change this color to your preferred hover color */
}

.dropdown-icon {
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.nav-item.dropdown:hover .dropdown-content {
  display: block;
}

.nav-item.dropdown:hover .dropdown-icon {
  transform: rotate(180deg);
}
.btnselect:hover {
  color: #000;
}
.btnselect {
  color: #000;
  text-align: justify;
}
.btnselect {
  border: 0px !important;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;

  cursor: pointer;
  outline: none;
  position: relative;
  width: auto;
  font-size: 16px;
}

/* Custom styling for option elements */
.btnselect option {
  background-color: #fff; /* Background color of options */
  color: #000; /* Text color */
  padding: 5px; /* Padding (note: may not work in all browsers) */
  font-size: 16px; /* Font size */
}

/* Additional option styling (may not work uniformly across all browsers) */
.btnselect option:checked {
  background-color: #f1f1f1; /* Background color for selected option */
  color: #000; /* Text color for selected option */
}

.btnselect option:hover {
  background-color: #f9f9f9; /* Background color when hovering */
}

.btnselect:focus {
  border-color: #000; /* Change border color on focus */
}
</style>
