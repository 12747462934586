<template>
  <TheNav />
  <div class="container py-5 pt-5 mt-5">
    <div class="row">
      <div class="col-md-6">
        <h3 class="colorBlue py-3">تسجيل الدخول اهلا بك مرة ثانية</h3>
        <div class="iconUser m-auto">
          <img class="w-100 h-100" src="../assets/user.svg" alt="notFound" />
        </div>
        <form @submit.prevent="login">
          <div class="mb-3">
            <label for="phone" class="form-label d-block">الايميل</label>
            <input
              type="text"
              v-model="phone"
              class="form-control"
              id="phone"
              aria-describedby="phoneHelp"
              placeholder="اكتب الايميل الخاص بك"
              required
            />
            <div class="alert alert-danger mt-2" role="alert" v-if="error">
              <pre>{{ error }}</pre>
            </div>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label d-block">كلمة المرور</label>
            <input
              type="password"
              v-model="password"
              class="form-control"
              id="password"
              placeholder="اكتب كلمة المرور"
              required
            />
          </div>
          <div class="mb-3 textRight">
            <a
              class="form-check-label colorOrange textRight"
              @click="$router.push({ name: 'forgetpass' })"
              >هل نسيت كلمة المرور؟</a
            >
          </div>
          <button type="submit" class="btn btn-primary w-75">
            تسجيل الدخول
          </button>
          <p class="font-weight-bold py-3">
            ليس لديك حساب؟
            <a class="colorOrange" @click="$router.push({ name: '_register' })"
              >إنشاء حساب</a
            >
          </p>
        </form>
      </div>
      <div class="col-md-6">
        <div class="bgImgLogin w-75 me-auto pt-5">
          <img src="../assets/Mobile login.png" class="w-100 h-100" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav";

export default {
  name: "log_in",
  components: {
    TheNav,
  },
  data() {
    return {
      phone: "",
      password: "",
      response: null,
      error: null,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("authToken");
      localStorage.removeItem("phone");
      this.$router.push({ name: "log_in" });
    },
    async login() {
      this.error = null; // Clear previous errors before making the request

      try {
        const response = await fetch("https://ecomerg.za3bot.com/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: this.phone,
            password: this.password,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.msg || "Error: " + response.statusText);
        }

        const data = await response.json();
        this.response = data;

        // Store the auth token and phone in local storage
        localStorage.setItem("authToken", data.data.api_token);
        localStorage.setItem("phone", this.phone);
        // console.log("wwww", data);
        // Redirect to home page after successful login
        this.$router.push({ name: "Home_Page" });
      } catch (error) {
        this.error = error.message || "An error occurred during login.";
      }
    },
  },
};
</script>

<style scoped>
label {
  text-align: right;
  font-weight: bold;
}

p {
  font-weight: bold;
}

a {
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 720px) {
  .bgImgLogin {
    margin: auto !important;
  }
}
</style>
