<template>
  <MyNavbar />
  <header class="d-flex justify-content-between p-2 container mt-4">
    <h3>إضافة طلب</h3>
    <button class="btn btn-primary">
      <font-awesome-icon :icon="['fas', 'circle-plus']" />
      <span class="mx-1">اضف عميل جديد</span>
    </button>
  </header>
  <div class="formAddOrder">
    <div class="container">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="my-2">
              <label for="">نوع الاوردر</label>
              <select class="w-100 form_select py-2">
                <option selected>نوع الطلب</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="my-2">
              <label for="">رقم الهاتف</label>
              <input
                type="number"
                class="form-control"
                placeholder="ادخل رقم الهاتف"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="my-2">
              <label for="">رقم هاتف اخر</label>
              <input
                type="number"
                class="form-control"
                placeholder="ادخل رقم الهاتف"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-2">
              <label for="">اسم العميل</label>
              <input
                type="text"
                class="form-control"
                placeholder="اسم العميل"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="my-2">
              <label for="">المحافظة</label>
              <select class="w-100 form_select py-2">
                <option selected>اختر المحافظة</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="my-2">
              <label for="">المدينة</label>
              <select class="w-100 form_select py-2">
                <option selected>اختر المدينة</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-2">
              <label for="">العنوان</label>
              <input type="text" class="form-control" placeholder="العنوان" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="my-2">
              <label for="">اسم المدريتور (اختياري)</label>
              <select class="w-100 form_select py-2">
                <option selected>اختر المدريتور</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="my-2">
              <label for="">المتجر الالكتروني (اختياري)</label>
              <select class="w-100 form_select py-2">
                <option selected>اختر المتجر الالكتروني</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-2">
              <label for="">ملاحظات العميل</label>
              <input
                type="text"
                class="form-control"
                placeholder="ملاحظات العميل"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="my-2">
              <table class="table text-center">
                <thead class="border">
                  <tr>
                    <th>المنتج</th>
                    <th>المقاس</th>
                    <th>اللون</th>
                    <th>الكمية</th>
                    <th>السعر</th>
                    <th>العمولة</th>
                    <th>الاجمالي</th>
                    <th>حذف</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        class="imgTableProduct"
                        src="../assets/trade-commerce-deal-economy-exchange-growth-concept.png"
                        alt=""
                      />
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>المقاس</option>
                        <option value="2">لارج</option>
                        <option value="3">المقاس</option>
                        <option value="4">المقاس</option>
                      </select>
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>اللون</option>
                        <option value="2">هافان</option>
                        <option value="3">هافان</option>
                        <option value="4">هافان</option>
                      </select>
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>الكمية</option>
                        <option value="2">5</option>
                        <option value="3">5</option>
                        <option value="4">5</option>
                      </select>
                    </td>
                    <td>300</td>
                    <td>50</td>
                    <td>1750</td>
                    <td>حذف</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        class="imgTableProduct"
                        src="../assets/trade-commerce-deal-economy-exchange-growth-concept.png"
                        alt=""
                      />
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>المقاس</option>
                        <option value="2">لارج</option>
                        <option value="3">المقاس</option>
                        <option value="4">المقاس</option>
                      </select>
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>اللون</option>
                        <option value="2">هافان</option>
                        <option value="3">هافان</option>
                        <option value="4">هافان</option>
                      </select>
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>الكمية</option>
                        <option value="2">5</option>
                        <option value="3">5</option>
                        <option value="4">5</option>
                      </select>
                    </td>
                    <td>300</td>
                    <td>50</td>
                    <td>1750</td>
                    <td>حذف</td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        class="imgTableProduct"
                        src="../assets/trade-commerce-deal-economy-exchange-growth-concept.png"
                        alt=""
                      />
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>المقاس</option>
                        <option value="2">لارج</option>
                        <option value="3">المقاس</option>
                        <option value="4">المقاس</option>
                      </select>
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>اللون</option>
                        <option value="2">هافان</option>
                        <option value="3">هافان</option>
                        <option value="4">هافان</option>
                      </select>
                    </td>
                    <td>
                      <select class="form_select">
                        <option value="1" selected>الكمية</option>
                        <option value="2">5</option>
                        <option value="3">5</option>
                        <option value="4">5</option>
                      </select>
                    </td>
                    <td>300</td>
                    <td>50</td>
                    <td>1750</td>
                    <td>حذف</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="d-flex justify-content-between">
              <label>الشحن</label>
              <label for=""><input type="checkbox" /> الآن</label>
              <label for=""><input type="checkbox" /> في وقت لاحق</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex justify-content-center">
              <label for="">
                كود الخصم <input type="text" class="bgInput"
              /></label>
            </div>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-md-4">
            <div class="d-flex">
              <label for="">
                اجمالي العمولة
                <input type="text" class="bgInput" placeholder="جنية 0"
              /></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <label for="">
                تكلفة الشحن
                <input type="text" class="bgInput" placeholder="جنية 0"
              /></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <label for="">
                ما سيدفعه العميل
                <input type="text" class="bgInput" placeholder="جنية 0"
              /></label>
            </div>
          </div>
        </div>
        <button class="btn btn-primary px-5">استكمال الطلب</button>
      </form>
    </div>
  </div>
  <MyFooter />
</template>
<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
export default {
  name: "_addorder",
  components: {
    MyNavbar,
    MyFooter,
  },
};
</script>
<style scoped>
.formAddOrder form label {
  display: block;
  font-weight: bold;
  padding-bottom: 5px;
}
.form_select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table th {
  background-color: #dddddd;
}
.table tbody tr td {
  background-color: #f9f9f9;
}
.imgTableProduct {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.bgInput {
  background-color: #f9f9f9;
  text-align: center;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
}
</style>
