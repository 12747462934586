import { createRouter, createWebHistory } from "vue-router";
import MyHome from "@/views/MyHome.vue";
import MyAbout from "@/views/About.vue";
import MyAboutSystem from "@/views/AboutSystem.vue";
import MyHowAdd from "@/views/HowAdd.vue";
import TheRegister from "@/views/register.vue";
import TheLogin from "@/views/login.vue";
import TheForget from "@/views/ForgetPassword.vue";
import TheChangePassword from "@/views/ChangePassword.vue";
import TheOtp from "@/views/otp.vue";
import TheDone from "@/views/Done.vue";
import MyHomePage from "@/views/HomePage.vue";
import MyProducts from "@/views/products.vue";
import Thedashboard from "@/views/dashboard.vue";
import TheaddOrder from "@/views/addOrder.vue";
import TheAdvancedStatistics from "@/views/AdvancedStatistics.vue";
import TheCommissionTransferRequests from "@/views/CommissionTransferRequests.vue";
import TheOrders from "@/views/Orders.vue";
import TheAccounts from "@/views/Accounts.vue";
import TheAddModerator from "@/views/AddModerator.vue";
import MyProfile from "@/views/MyProfile.vue";
import NotFound from "@/views/NotFound.vue";

// Create the router instance
const router = createRouter({
  history: createWebHistory(), // Use history mode
  routes: [
    {
      path: "/",
      name: "My_Home",
      component: MyHome,
      meta: { title: "Home" },
    },
    {
      path: "/About",
      name: "A_bout",
      component: MyAbout,
      meta: { title: "About" },
    },
    {
      path: "/HomePage",
      name: "Home_Page",
      component: MyHomePage,
      meta: { title: "HomePage" },
    },
    {
      path: "/AboutSystem",
      name: "AboutSystem",
      component: MyAboutSystem,
      meta: { title: "About System" },
    },
    {
      path: "/products",
      name: "product",
      component: MyProducts,
      meta: { title: "product" },
    },
    {
      path: "/MyProfile",
      name: "MyProfile",
      component: MyProfile,
      meta: { title: "MyProfile" },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Thedashboard,
      meta: { title: "dashboard", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/AdvancedStatistics",
      name: "AdvancedStatistics",
      component: TheAdvancedStatistics,
      meta: { title: "AdvancedStatistics", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/Orders",
      name: "Orders",
      component: TheOrders,
      meta: { title: "Orders", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/CommissionTransferRequests",
      name: "CommissionTransferRequests",
      component: TheCommissionTransferRequests,
      meta: { title: "CommissionTransferRequests", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/Accounts",
      name: "Accounts",
      component: TheAccounts,
      meta: { title: "Accounts", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/AddModerator",
      name: "AddModerator",
      component: TheAddModerator,
      meta: { title: "AddModerator", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/addOrder",
      name: "addOrder",
      component: TheaddOrder,
      meta: { title: "add order", requiresAuth: true }, // Protect with auth
    },
    {
      path: "/HowAdd",
      name: "HowAdd",
      component: MyHowAdd,
      meta: { title: "How Add" },
    },
    {
      path: "/login",
      name: "log_in",
      component: TheLogin,
      meta: { title: "Log in" },
    },
    {
      path: "/register",
      name: "_register",
      component: TheRegister,
      meta: { title: "register" },
    },
    {
      path: "/ForgetPassword",
      name: "forgetpass",
      component: TheForget,
      meta: { title: "Forget Password" },
    },
    {
      path: "/otp",
      name: "otp",
      component: TheOtp,
      meta: { title: "otp" },
    },
    {
      path: "/ChangePassword",
      name: "ChangePassword",
      component: TheChangePassword,
      meta: { title: "ChangePassword" },
    },
    {
      path: "/Done",
      name: "Done",
      component: TheDone,
      meta: { title: "Done" },
    },
    {
      name: "NotFound",
      path: "/:pathMatch(.*)*",
      component: NotFound,
    },
  ],
});

// Add the global authentication guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("authToken");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "log_in" });
    } else {
      next();
    }
  } else if (
    isAuthenticated &&
    (to.name === "_register" || to.name === "log_in")
  ) {
    next({ name: "Home_Page" });
  } else {
    next();
  }
});

export default router;
