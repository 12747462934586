<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"
        ><img src="../assets/logo.png" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <form class="d-flex mx-2" role="search">
              <input
                class="form-control inputSearh me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn btnSearch" type="submit">
                <font-awesome-icon :icon="['fas', 'search']" />
              </button>
            </form>
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"
              ><font-awesome-icon :icon="['fas', 'heart']"
            /></a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggl"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome-icon :icon="['fas', 'comment-alt']" />
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggl"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome-icon :icon="['fas', 'bell']" />
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggl"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome-icon :icon="['fas', 'cart-plus']" />
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              style="width: 200px; left: 15%; right: 15%; text-align: justify"
              class="nav-link dropdown-toggl position-absolute"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                ><img
                  src="../assets/user.svg"
                  class="imgUser"
                  alt="noutfound"
                />
                Mohamed salah</span
              >
              <small
                style="display: block; text-align: center"
                class="colorOrange"
                >كودالافيليت :123456</small
              >
            </a>
            <ul class="dropdownMenuProfile dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/MyProfile"
                  >Profile</router-link
                >
              </li>
              <li><a class="dropdown-item" href="#">Settings</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#" @click="logout">Logout</a>
              </li>
            </ul>
          </li>
        </ul>
        <form class="d-flex mx-2" role="search">
          <ul class="list-unstyled">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle pt-4"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                العربيه
              </a>
              <ul
                class="dropdown-menu langug"
                style="width: 120px; height: 80px; overflow: hidden"
              >
                <li><a class="dropdown-item" href="#">العربيه</a></li>
                <li><a class="dropdown-item" href="#">English</a></li>
              </ul>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </nav>
  <header class="pt-5 mt-5 d-flex">
    <div class="dropdown allFile" style="text-align: justify">
      <button
        class="btn btn-primary dropdown-toggle px-4 py-2"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <font-awesome-icon :icon="['fas', 'th-list']" class="ms-3" />
        جميع الفئات
      </button>
      <ul class="dropdown-menu">
        <li class="py-1 my-1">
          <router-link
            to="/category/skin-care"
            class="dropdown-item d-flex justify-content-between"
          >
            <span>منتجات العناية بالبشرة</span>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="pt-1" />
          </router-link>
        </li>
        <li class="py-1 my-1">
          <router-link
            to="/category/body-care"
            class="dropdown-item d-flex justify-content-between"
          >
            <span>منتجات العناية بالجسم</span>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="pt-1" />
          </router-link>
        </li>
        <li class="py-1 my-1">
          <router-link
            to="/category/perfumes"
            class="dropdown-item d-flex justify-content-between"
          >
            <span>عطور</span>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="pt-1" />
          </router-link>
        </li>
        <li class="py-1 my-1">
          <router-link
            to="/category/personal-care"
            class="dropdown-item d-flex justify-content-between"
          >
            <span>منتجات عناية شخصية</span>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="pt-1" />
          </router-link>
        </li>
        <li class="py-1 my-1">
          <router-link
            to="/category/shoes"
            class="dropdown-item d-flex justify-content-between"
          >
            <span>احذية</span>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="pt-1" />
          </router-link>
        </li>
        <li class="py-1 my-1">
          <router-link
            to="/category/clothing"
            class="dropdown-item d-flex justify-content-between"
          >
            <span>ملابس</span>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="pt-1" />
          </router-link>
        </li>
      </ul>
    </div>
    <router-link
      to="/HomePage"
      class="pt-2 text-decoration-none text-dark ms-3"
    >
      الرئيسية
    </router-link>
    <router-link
      to="/products"
      class="pt-2 text-decoration-none text-dark ms-3"
    >
      المنتجات
    </router-link>
    <router-link
      to="/dashboard"
      class="pt-2 text-decoration-none text-dark ms-3"
    >
      لوحة التحكم
    </router-link>
    <router-link
      to="/addOrder"
      class="pt-2 text-decoration-none text-dark ms-3"
    >
      اضف طلب
    </router-link>
  </header>
</template>
<script>
export default {
  name: "_navbar",
  methods: {
    logout() {
      // Remove the authentication token and other related data from localStorage
      localStorage.removeItem("authToken");
      localStorage.removeItem("userData");

      // Redirect to the login page
      this.$router.push({ name: "log_in" });
    },
  },
};
</script>
<style scoped>
header {
  gap: 30px;
  box-shadow: 0px 3px 6px #00000029;
}
.btnSearch {
  background-color: #0c2d58;
  color: #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btnSearch:hover {
  background-color: #0c2d58;
  color: #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.inputSearh {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 400px;
}
.navbar-brand {
  width: 100px;
  height: 60px;
}
.navbar-brand img {
  width: 100%;
  height: 100%;
}
.nav-item {
  margin: 0px 20px;
}
.dropdown-menu {
  width: 280px;
  height: 300px;
  overflow-y: auto;
}
.imgUser {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.langug {
  text-align: justify;
}
.buttonprim {
  border-radius: 0px;
}
.btn-primary:hover {
  background-color: #0c2d58;
}
.router-link-active {
  font-weight: bold;
  color: #fd6735 !important;
  border-bottom: 2px solid #fd6735;
  padding-bottom: 10px;
}
.dropdownMenuProfile {
  height: 130px !important;
  width: 200px !important;
  overflow: hidden;
}
@media (max-width: 768px) {
  .inputSearh {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 200px;
  }
  .nav-item {
    margin: 10px 20px;
  }
  header {
    display: block !important;
  }
  .allFile {
    margin: 20px 0px;
  }
  form ul {
    margin: 30px auto;
  }
}
</style>
