<template>
  <TheNav />
  <div class="secForgetpass pt-5 mt-5">
    <h3 class="py-2 text-center">التحقق من الرمز</h3>
    <div class="imgForgetpass">
      <img class="w-100 h-100" src="../assets/otp.svg" alt="not found" />
    </div>
    <p class="py-3">ادخل الرمز الذي تم ارساله الي الايميل الخاص بك</p>
    <div class="inputForget w-50 m-auto mt-2 text-center">
      <form @submit.prevent="sendOtpCode">
        <div class="w-50 m-auto" style="gap: 10px; display: flex">
          <input
            type="text"
            class="inputOtp mx-2 bg-light border-0 p-2"
            v-model="otpCode[0]"
            maxlength="1"
            pattern="[0-9]*"
            ref="otp1"
            @input="validateInput($event, 0, 'otp2')"
          />
          <input
            type="text"
            class="inputOtp mx-2 bg-light border-0 p-2"
            v-model="otpCode[1]"
            maxlength="1"
            pattern="[0-9]*"
            ref="otp2"
            @input="validateInput($event, 1, 'otp3')"
          />
          <input
            type="text"
            class="inputOtp mx-2 bg-light border-0 p-2"
            v-model="otpCode[2]"
            maxlength="1"
            pattern="[0-9]*"
            ref="otp3"
            @input="validateInput($event, 2, 'otp4')"
          />
          <input
            type="text"
            class="inputOtp mx-2 bg-light border-0 p-2"
            v-model="otpCode[3]"
            maxlength="1"
            pattern="[0-9]*"
            ref="otp4"
            @input="validateInput($event, 3, 'otp5')"
          />
          <input
            type="text"
            class="inputOtp mx-2 bg-light border-0 p-2"
            v-model="otpCode[4]"
            maxlength="1"
            pattern="[0-9]*"
            ref="otp5"
            @input="validateInput($event, 4)"
          />
        </div>
        <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
          {{ errorMessage }}
        </div>
        <div
          v-if="successMessage"
          class="alert alert-success mt-3"
          role="alert"
        >
          {{ successMessage }}
        </div>
        <span class="colorOrange d-block text-center py-2" v-if="timer > 0">
          {{ timer }} seconds remaining
        </span>
        <a class="d-block colorBlue text-center" @click.prevent="resendOtp">
          إعادة ارسال الرمز
        </a>
        <button
          class="btn btn-primary px-3 text-center w-50 my-3"
          type="submit"
        >
          التالي
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav";

export default {
  name: "_otp",
  data() {
    return {
      otpCode: Array(5).fill(""), // Initialize otpCode as an array of 5 empty strings
      phone: "", // Variable to hold the user's phone number
      timer: 0, // Timer for resend OTP
      errorMessage: "", // To store error messages
      successMessage: "", // To store success messages
    };
  },
  components: {
    TheNav,
  },
  created() {
    // Retrieve the phone number from localStorage when the component is created
    this.phone = localStorage.getItem("phone");

    if (!this.phone) {
      this.errorMessage =
        "Phone number not found. Please go back and enter your phone number.";
    }

    this.startTimer(); // Start the timer when the component is created
  },
  methods: {
    validateInput(event, index, nextFieldRef) {
      const value = event.target.value;
      if (!/^[0-9]*$/.test(value)) {
        this.otpCode[index] = value.replace(/[^0-9]/g, "");
      } else {
        this.otpCode[index] = value; // Update otpCode with the valid digit
      }

      console.log("OTP Code:", this.otpCode); // Log the otpCode array

      // Move focus to the next input field if a valid digit is entered
      if (value.length === 1 && nextFieldRef) {
        this.$refs[nextFieldRef].focus();
      } else if (value.length === 0 && index > 0) {
        this.$refs[`otp${index}`].focus();
      }
    },
    async sendOtpCode() {
      const otpCode = this.otpCode.join(""); // Combine OTP code into a single string
      console.log("Final OTP Code:", otpCode); // Log the final otpCode string

      if (otpCode.length !== 5) {
        this.errorMessage = "Please enter all 5 digits.";
        return;
      }

      if (!this.phone) {
        this.errorMessage = "Phone number is missing.";
        return;
      }

      const requestBody = {
        code: otpCode, // Use `code` as the field name for OTP
        phone: this.phone, // Include the phone number in the request
      };

      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/active/account",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.msg || "Failed to verify OTP.");
        }

        // Handle success response
        this.successMessage = "OTP verified successfully";
        this.errorMessage = "";

        // Navigate to the next page or perform another action
        this.$router.push({ name: "ChangePassword" });
      } catch (error) {
        // Handle error response
        this.errorMessage = error.message || "Failed to verify OTP.";
        this.successMessage = "";
        console.error("There was a problem with the fetch operation:", error);
      }
    },
    async resendOtp() {
      if (this.timer > 0) {
        alert("You can resend OTP only after the timer expires.");
        return;
      }

      if (!this.phone) {
        this.errorMessage = "Phone number is missing.";
        return;
      }

      try {
        const requestBody = {
          phone: this.phone, // Include the phone number in the request
        };

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/resend/message",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.msg || "Failed to resend OTP.");
        }

        // Handle success response
        this.successMessage = "OTP has been resent. Check your phone or email.";
        this.errorMessage = "";

        // Restart timer
        this.startTimer();
      } catch (error) {
        // Handle error response
        this.errorMessage = error.message || "Failed to resend OTP.";
        this.successMessage = "";
        console.error("There was a problem with the fetch operation:", error);
      }
    },
    startTimer() {
      this.timer = 60; // Set initial timer value in seconds
      const interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(interval);
        } else {
          this.timer--;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
body {
  overflow: hidden;
}
.imgForgetpass {
  width: 280px;
  height: 250px;
  margin: auto;
  text-align: center;
}
.secForgetpass p {
  font-weight: bold;
  text-align: center;
}
.inputOtp {
  width: 50px;
  text-align: center;
}
@media (max-width: 720px) {
  input.form-control {
    width: 80% !important;
  }
  .inputForget {
    width: 80% !important;
  }
}
</style>
