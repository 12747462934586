<template>
  <MyNavbar />
  <div class="listNav" @click="toggleNav">
    <div class="d-flex">
      <font-awesome-icon :icon="['fas', 'list']" />
      <span class="d-block px-2">القائمة</span>
    </div>
  </div>
  <MyHeader />
  <div class="form-container">
    <h2>إضافة مديرتور</h2>
    <form action="#" method="POST">
      <div class="form-group">
        <label for="name">الاسم بالكامل</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="اكتب اسم المديرتور"
          required
        />
      </div>
      <div class="form-group">
        <label for="email">البريد</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="اكتب البريد الخاص به"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">كلمة المرور</label>
        <input
          type="password"
          id="password"
          name="password"
          placeholder="اكتب كلمة المرور"
          required
        />
      </div>
      <div class="form-group">
        <button type="submit" class="submit-btn">تسجيل</button>
      </div>
    </form>
    <div class="table-container mt-5">
      <h2>المديرتور الحاليين</h2>
      <table>
        <thead>
          <tr>
            <th>رقم</th>
            <th>الاسم</th>
            <th>البريد</th>
            <th>الطلبات</th>
            <th>الحالة</th>
            <th>حظر المديرتور</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>محمد علي</td>
            <td>mohamedali@email.com</td>
            <td><input type="checkbox" checked /> عرض كل الطلبات</td>
            <td>غير محظور</td>
            <td>
              <button class="ban-btn">حظر</button>
              <button class="delete-btn">🗑️</button>
              <a href="#" class="edit-link">🔗</a>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>محمد علي</td>
            <td>mohamedali@email.com</td>
            <td><input type="checkbox" checked /> عرض كل الطلبات</td>
            <td>غير محظور</td>
            <td>
              <button class="ban-btn">حظر</button>
              <button class="delete-btn">🗑️</button>
              <a href="#" class="edit-link">🔗</a>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td>محمد علي</td>
            <td>mohamedali@email.com</td>
            <td><input type="checkbox" checked /> عرض كل الطلبات</td>
            <td>غير محظور</td>
            <td>
              <button class="ban-btn">حظر</button>
              <button class="delete-btn">🗑️</button>
              <a href="#" class="edit-link">🔗</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
import MyHeader from "@/components/MyHeader.vue";
export default {
  name: "_AddModerator",
  components: {
    MyNavbar,
    MyFooter,
    MyHeader,
  },
  data() {
    return {
      isNavVisible: true,
    };
  },
  methods: {},
  async mounted() {},
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.navbar .nav-item {
  font-weight: bold;
}
.listNav {
  padding: 10px;
  background-color: #0c2d58;
  text-align: right;
  width: 40%;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: none;
}
.dashboard-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.form-control {
  border-radius: 5px;
}
.search-btn {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.search-btn:hover {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.inputSearsh {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
}
.divCont {
  height: 200px;
  margin: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.divCont h4:after {
  content: "";
  background-color: #fd6735;
  width: 60px;
  height: 4px;
  display: block;
  margin: 25px auto;
}
.form-container {
  width: 90%;
  margin: 10px auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: right;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-size: 14px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

input::placeholder {
  color: #ccc;
}

button.submit-btn {
  width: 200px;
  padding: 12px;
  background-color: #00224f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button.submit-btn:hover {
  background-color: #001c3d;
}
h2 {
  text-align: right;
  color: #333;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f8f8;
  color: #ff5722; /* Orange color for headers */
  font-weight: bold;
}

td {
  color: #333;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.ban-btn {
  background-color: #fff;
  color: #ee0000;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.ban-btn:hover {
  background-color: #fff;
}

.delete-btn {
  background-color: transparent;
  color: #ee0000;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}

.edit-link {
  margin-left: 10px;
  font-size: 18px;
  text-decoration: none;
  color: #007bff;
}

.edit-link:hover {
  color: #0056b3;
}
</style>
