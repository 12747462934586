<template>
  <TheNav />
  <div class="secForgetpass pt-5 mt-5">
    <h3 class="py-2 text-center">هل نسيت كلمة السر؟</h3>
    <div class="imgForgetpass">
      <img
        class="w-100 h-100"
        src="../assets/Forgot password-pana.svg"
        alt="not found"
      />
    </div>
    <p class="py-3 text-center">
      لا تقلق ما عليك سوى كتابة رقم الهاتف الخاص بك وسنرسل اليك رمز التأكيد
    </p>
    <div class="inputForget w-50 m-auto mt-2 text-center">
      <form @submit.prevent="resetPassword">
        <input
          type="text"
          class="form-control w-75 m-auto bg-light border-0 p-2"
          placeholder="اكتب رقم الهاتف الخاص بك"
          v-model="phone"
          required
        />

        <!-- Error Alert -->
        <div v-if="error" class="text-danger mt-2">
          {{ error }}
        </div>

        <!-- Success Alert -->
        <div v-if="response" class="text-success mt-2">
          {{ response.message }}
        </div>

        <button
          class="btn btn-primary text-center px-3 w-50 my-3"
          type="submit"
        >
          التالي
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav";

export default {
  name: "ForgetPassword",
  components: {
    TheNav,
  },
  data() {
    return {
      phone: "",
      response: null,
      error: null,
    };
  },
  methods: {
    async resetPassword() {
      this.clearAlerts(); // Clear any existing alerts before making the request

      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/reset-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ phone: this.phone }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.msg || `Error: ${response.statusText}`);
        }

        this.response = await response.json();
        console.log("Password reset initiated:", this.response);

        // Store the phone number in localStorage
        localStorage.setItem("phone", this.phone);

        // Clear the phone input after a successful response
        this.phone = "";

        // Redirect to OTP page after successful password reset initiation
        this.$router.push({ name: "otp" });
      } catch (error) {
        this.error = error.message;
        console.error("Password reset failed:", error);
      }
    },
    clearAlerts() {
      this.response = null;
      this.error = null;
    },
  },
};
</script>

<style scoped>
.imgForgetpass {
  width: 280px;
  height: 250px;
  margin: auto;
}
.secForgetpass h3 {
  text-align: center;
}
.secForgetpass p {
  font-weight: bold;
  text-align: center;
}
@media (max-width: 720px) {
  input.form-control {
    width: 80% !important;
  }
  .inputForget {
    width: 80% !important;
  }
}
</style>
