<template>
  <TheNav />
  <div class="secHome mt-5 pt-5">
    <div class="container textRight">
      <div class="row">
        <div class="col-md-6">
          <h2 class="py-2 pt-5">
            <span class="colorBlue">E-</span
            ><span class="colorOrange">comtail</span>
          </h2>
          <p class="pb-2">
            اكبر واول منصه فى مصر بتقدم خدمات متكامله لكل من يحلم ببناء كيان
            تجارى الكترونى,بأعلى معدلات ربح على الاطلاق وهتبدأ مشروع بدون اى
            تكاليف من غير رأس المال او تحمل اى خساره .لان ببساطه بنوفر الدوره
            الكامله لعمليه البيع بدايه من توفير اكبر عدد من المنتجات مبيعا
            وبأعلى ربح متوقع واكتر من نظام تشتغل بيه وخدمه تجهيز وتغليف وكمان
            بنقدم خدمه الشحن من خلال مناديبنا وفروعنا الخاصه فى جميع انحاء
            الجمهورية
          </p>
          <button
            class="btn btn-primary px-5 mx-2"
            @click="$router.push({ name: '_register' })"
          >
            ابدأ الآن
          </button>
        </div>
        <div class="col-md-6">
          <div class="imgStartHome">
            <img
              class="w-100 h-100"
              src="../assets/Mobile login.png"
              alt="NotFound"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="DivAbout">
      <div class="row">
        <div class="col-md-12">
          <h3 class="text-center py-5 mt-5">من نحن؟</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="imgStartHome">
            <img
              class="w-100 h-100"
              src="../assets/Mobile login.png"
              alt="NotFound"
            />
          </div>
        </div>
        <div class="col-md-5">
          <p class="pt-5 p-3">
            اكبر واول منصه فى مصر بتقدم خدمات متكامله لكل من يحلم ببناء كيان
            تجارى الكترونى,بأعلى معدلات ربح على الاطلاق وهتبدأ مشروع بدون اى
            تكاليف من غير رأس المال او تحمل اى خساره .لان ببساطه بنوفر الدوره
            الكامله لعمليه البيع بدايه من توفير اكبر عدد من المنتجات مبيعا
            وبأعلى ربح متوقع واكتر من نظام تشتغل بيه وخدمه تجهيز وتغليف وكمان
            بنقدم خدمه الشحن من خلال مناديبنا وفروعنا الخاصه فى جميع انحاء
            الجمهورية
          </p>
        </div>
      </div>
    </div>
    <div class="secWhyDay my-5">
      <h3 class="py-5">لماذا عليك ان تنضم اليوم إلينا</h3>
      <div class="container">
        <div class="divWhy d-flex justify-content-between">
          <div class="boxWhyDay border-bottom border-left">
            <div class="w-75 m-auto pt-3">
              <img
                src="../assets/5027847_cash_dollar_money_rearch_icon.svg"
                alt="noutfound"
              />
              <h5 class="py-3 colorBlue">رأس مال بسيط</h5>
              <p>مشروع احلامك برأس مال بسيط لا يتخطي 100 دينار</p>
            </div>
          </div>
          <div class="boxWhyDay border-bottom border-left">
            <div class="w-75 m-auto pt-3">
              <img
                src="../assets/5027885_dollar_finance_money_online_payment_icon.svg"
                alt="noutfound"
              />
              <h5 class="py-3 colorBlue">مرونة في سعر البيع</h5>
              <p>
                يمكنك تحديد السعر البيع حسب متطلباتك نعطيك فقط السعر الاعلي
                والاقل
              </p>
            </div>
          </div>
          <div class="boxWhyDay border-bottom">
            <div class="w-75 m-auto pt-3">
              <img
                src="../assets/6556717_announce_bullhorn_communication_megaphone_speaker_icon.svg"
                alt="noutfound"
              />
              <h5 class="py-3 colorBlue">التسويق مهمتك الوحيدة</h5>
              <p>فقط مهمتك الوحيدة هي التسويق من خلال المنصة التي تناسبك</p>
            </div>
          </div>
        </div>
        <div class="divWhy d-flex justify-content-between">
          <div class="boxWhyDay border-top border-left">
            <div class="w-75 m-auto pt-3">
              <img src="../assets/299102_box_in_icon.svg" alt="noutfound" />
              <h5 class="py-3 colorBlue">تنوع المنتجات</h5>
              <p>لدينا اكثر من 15000 منتج لكي لا تكون محدود بمنتجات قليلة</p>
            </div>
          </div>
          <div class="boxWhyDay border-top border-left">
            <div class="w-75 m-auto pt-3">
              <img
                src="../assets/5027880_bag_business_currency_dollar_finance_icon.svg"
                alt="noutfound"
              />
              <h5 class="py-3 colorBlue">عمولة وربح صافي كبير</h5>
              <p>يتيح لك السيستم اكبر العمولات والارباح التي تحلم بها واكثر</p>
            </div>
          </div>
          <div class="boxWhyDay border-top">
            <div class="w-75 m-auto pt-3">
              <img
                src="../assets/5027820_dollar_investment_money_icon.svg"
                alt="noutfound"
              />
              <h5 class="py-3 colorBlue">سحب ارباحك بكل سهولة</h5>
              <p>لدينا اكثر من طريقة للدفع ما يمكنك من سحب ارباحك بكل سهوله</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sharhSystem py-5">
      <div class="container">
        <h3 class="py-3">شرح السيستم</h3>
        <p class="pb-4">
          خليك عضو اساسي من كيان بيهتم بيك وبشغلك ويسعي دايما بأنه يقدملك افضل
          خدمه تقدر من خلالها تبني البراند الخاص بيك وتزود ارباحك من خلال تيم
          قوي بيحاول دايما ان يقدم افضل ما يمتلك خدمه تقدر من خلالها تبني
          البراند الخاص بيك وتزود ارباحك من خلال تيم قوي بيحاول دايما ان يقدم
          افضل ما يمتلك
        </p>
        <div class="divcontainerVideo">
          <iframe
            src="https://www.youtube.com/embed/NaRkGTRDiLQ?si=SUFFPR7uHGwhnRTo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div class="divBorderVid"></div>
      </div>
    </div>
    <div class="secOneTwoThree my-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="divOne">
              <div class="d-flex">
                <img src="../assets/Group 45694.svg" alt="notfound" />
                <h5 class="colorOrange mx-2 pt-3">سجل</h5>
              </div>
              <p>
                سجل معانا كمسوق لجمال من خلال عمل اكونت خاص بيك كمسوق و ابني
                البراند الخاص بيك وتزود ارباحك من خلال تيم قوي
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="divOne">
              <div class="d-flex">
                <img src="../assets/Group 45695.svg" alt="notfound" />
                <h5 class="colorOrange mx-2 pt-3">اطلب</h5>
              </div>
              <p>
                اطلب من خلال سيستم بسيط وواضح بيساعدك في اختيار منتجاتك بكل
                تفاصيلها علشان تبدأ في الاعلان عنها وتحقيق ارباحك
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="divOne">
              <div class="d-flex">
                <img src="../assets/Group 45696.svg" alt="notfound" />
                <h5 class="colorOrange mx-2 pt-3">استلم عمولتك</h5>
              </div>
              <p>
                هتربح يعني هتربح ودا لاننا حققنا المعادله الصعبه " اعلي جوده
                واقل سعر واعلي ربح " لانك هتلاقي اكتر من نظام تشتغل
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="secPrroductInHome">
      <div class="container">
        <div class="d-flex justify-content-between mb-3">
          <h3>المنتجات</h3>
          <a class="colorOrange" @click="showAllProducts = !showAllProducts">
            {{ showAllProducts ? "عرض أقل" : "عرض الكل" }}
          </a>
        </div>
        <div class="row py-2">
          <div
            class="col-md-3"
            v-for="product in displayedProducts"
            :key="product.id"
          >
            <div class="DivProduct border">
              <div class="productImage">
                <img :src="product.photo" alt="notFound" />
              </div>
              <div class="contentProduct">
                <p>{{ product.name }}</p>
                <div class="d-flex justify-content-between">
                  <span class="colorOrange d-flex">
                    <span>السعر :</span>
                    <span>{{ product.purchasing_price }}</span>
                  </span>
                  <span class="colorOrange d-flex">
                    <span>العمولة :</span>
                    <span>{{ product.commission }}</span>
                  </span>
                </div>
                <small class="pt-2">متوفر في المخزون 14</small>
                <div class="d-flex justify-content-between mt-3">
                  <button class="btn btn-primary px-3">
                    <font-awesome-icon
                      class="text-white"
                      :icon="['fas', 'cart-shopping']"
                    />
                    اضف الى المفضلة
                  </button>
                  <button class="btn border btnBorderRadius">
                    <font-awesome-icon
                      :icon="['fas', 'heart']"
                      :class="{ activeHeart: isActiveHeart }"
                      @click="toggleActive"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TheFoot />
</template>
<script>
import TheNav from "@/components/TheNav";
import TheFoot from "@/components/footer";
export default {
  name: "My_Home",
  components: {
    TheNav,
    TheFoot,
  },
  data() {
    return {
      isActiveHeart: false,
      showAllProducts: false, // To control the display of all products
      products: [],
    };
  },
  computed: {
    displayedProducts() {
      // Return either a limited number of products or all products
      return this.showAllProducts ? this.products : this.products.slice(0, 4);
    },
  },
  methods: {
    toggleActive() {
      this.isActiveHeart = !this.isActiveHeart;
    },
    async getProducts() {
      try {
        const response = await fetch("https://ecomerg.za3bot.com/api/home");
        const data = await response.json();
        this.products = data.data.recently_arrived;
        console.log("this products", this.products.data.recently_arrived);
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    },
  },
  async mounted() {
    await this.getProducts();
  },
};
</script>
<style scoped>
.secHome h2,
[dir="ltr"] h3 {
  font-weight: bold;
  text-align: left;
}
[dir="rtl"] h3 {
  font-weight: bold;
  text-align: right;
}

.secHome p {
  font-weight: 500;
  line-height: 2;
  text-align: justify;
}
.secHome .imgStartHome {
  width: 100%;
  height: 400px;
}
.boxWhyDay {
  width: 33.3%;
  height: 250px;
  text-align: center;
  padding: 10px;
}
.boxWhyDay img {
  width: 50px;
  height: 50px;
}
.boxWhyDay p {
  color: #8e8e8e;
  text-align: center;
}
.border-bottom {
  border-bottom: 1px solid #0c2d58 !important;
}
.border-left {
  border-left: 1px solid #0c2d58 !important;
}
.border-top {
  border-top: 1px solid #0c2d58 !important;
}
.sharhSystem p {
  font-weight: bold;
  line-height: 2.3;
}
.divcontainerVideo {
  width: 50%;
  min-height: 300px;
  margin: auto;
  border: 20px solid #0c2d58;
  border-radius: 22px;
  overflow: hidden;
}
.divcontainerVideo iframe {
  width: 100%;
  height: 300px;
}
.divcontainerVideo video {
  width: 100%;
  height: 100%;
}
.divBorderVid {
  width: 65%;
  height: 25px;
  border-radius: 70%;
  margin: auto;
  border: 20px solid #0c2d58;
  margin-top: -2px;
}
.secOneTwoThree {
  background: #f5f5f5;
  padding: 30px 10px;
}
.secOneTwoThree p {
  color: #565656;
  line-height: 2.3;
  text-align: justify;
}
.secOneTwoThree .divOne {
  width: 85%;
}
.secOneTwoThree .divOne img {
  width: 50px;
  height: 50px;
}
.secPrroductInHome a {
  text-decoration: none;
  font-weight: bold;
}
.DivProduct {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}
.DivProduct .productImage {
  width: 100%;
  height: 60%;
}
.DivProduct .productImage img {
  width: 100%;
  height: 100%;
}
.contentProduct {
  padding: 5px;
}
.contentProduct p {
  color: #0c2d58;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 18px;
}
.contentProduct span {
  font-weight: bold;
  text-align: justify;
  display: block;
  font-size: 14px;
}
.contentProduct small {
  display: block;
  color: #565656;
  text-align: justify;
}
.btnBorderRadius {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activeHeart {
  color: red; /* Change this to the color you want when active */
}

font-awesome-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}
@media (max-width: 720px) {
  .boxWhyDay {
    width: 90%;
    margin: 10px auto;
    background: #fafafa;
    border: none !important;
    border-radius: 7px;
    overflow: hidden;
  }
  .divWhy {
    display: block !important;
  }
  .divcontainerVideo {
    height: 166px;
    width: 90%;
  }
  .divcontainerVideo iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
