<template>
  <TheNav />
  <div class="secForgetpass pt-5 mt-5">
    <h3 class="pt-2 text-center">كلمة مرور جديدة</h3>
    <div class="imgForgetpass">
      <img
        class="w-100 h-100"
        src="../assets/changepassword.svg"
        alt="not found"
      />
    </div>
    <p class="py-1 text-center">اكتب كلمة المرور الجديدة لتسجيل الدخول</p>
    <div class="inputForget text-center w-50 m-auto mt-2">
      <form @submit.prevent="changePassword">
        <input
          type="password"
          class="form-control w-75 m-auto bg-light border-0 p-2"
          v-model="newPassword"
          placeholder="اكتب كلمة المرور"
          required
          minlength="6"
        />
        <input
          type="password"
          class="form-control w-75 m-auto bg-light my-3 border-0 p-2"
          v-model="password_confirmation"
          @input="validatePassword"
          placeholder="اعد كتابة كلمة المرور"
          required
          minlength="6"
        />
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <button class="btn btn-primary px-3 w-50 my-3" type="submit">
          انشاء كلمة المرور الجديدة
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav";

export default {
  name: "ChangePassword",
  components: {
    TheNav,
  },
  data() {
    return {
      newPassword: "",
      password_confirmation: "",
      errorMessage: "", // To store error messages
    };
  },
  methods: {
    validatePassword() {
      const pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (!pattern.test(this.password_confirmation)) {
        this.errorMessage =
          "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل، وحرف كبير، وحرف صغير، ورقم، ورمز خاص.";
      } else {
        this.errorMessage = ""; // Clear error message if valid
      }
    },
    async changePassword() {
      // Clear any previous error message
      this.errorMessage = "";

      // Front-end validation: Check if passwords match
      if (this.newPassword !== this.password_confirmation) {
        this.errorMessage = "كلمات المرور غير متطابقة";
        return;
      }

      const phoneNumber = localStorage.getItem("phone");
      if (!phoneNumber) {
        this.errorMessage = "لم يتم العثور على رقم الهاتف";
        return;
      }

      const requestBody = {
        phone: phoneNumber,
        password: this.newPassword,
        password_confirmation: this.password_confirmation,
      };

      try {
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/new-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        const responseData = await response.json();

        if (!response.ok || responseData.status === 0) {
          if (responseData.data && responseData.data.password) {
            this.errorMessage = responseData.data.password[0];
          } else {
            this.errorMessage = responseData.msg || "حدث خطأ غير متوقع.";
          }
          return;
        }

        alert("تم تغيير كلمة المرور بنجاح");
        this.$router.push({ name: "Done" });
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        this.errorMessage = "فشل في تغيير كلمة المرور";
      }
    },
  },
};
</script>

<style scoped>
.imgForgetpass {
  width: 280px;
  height: 250px;
  margin: auto;
}
.secForgetpass p {
  font-weight: bold;
}
@media (max-width: 720px) {
  .form-control {
    width: 80% !important;
  }
  .inputForget {
    width: 80% !important;
  }
}
</style>
