<template>
  <MyNavbar />
  <div class="listNav" @click="toggleNav">
    <div class="d-flex">
      <font-awesome-icon :icon="['fas', 'list']" />
      <span class="d-block px-2">القائمة</span>
    </div>
  </div>
  <MyHeader />
  <div class="container mt-5">
    <div class="row mb-3">
      <div class="col-12">
        <div class="d-flex justify-content-between py-3 pb-5">
          <h4 class="dashboard-title m-0 p-0">الطلبات</h4>
          <button class="btn btn-primary">
            <font-awesome-icon :icon="['fas', 'circle-plus']" />
            <span class="mx-1">اضف عميل جديد</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-md-12">
        <div class="d-flex justify-content-between">
          <div class="input-group">
            <input
              type="text"
              class="inputSearsh form-control"
              placeholder="بحث برقم المنتج.."
              aria-label="بحث"
            />
            <button class="btn search-btn">بحث</button>
          </div>
          <select class="btn">
            <option selected>عرض الكل</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row g-3 my-3">
      <div class="col-md-4">
        <input
          type="text"
          onfocus="(this.type = 'date')"
          class="form-control"
          placeholder="تاريخ النهاية.."
        />
      </div>
      <div class="col-md-4">
        <input
          type="text"
          onfocus="(this.type = 'date')"
          class="form-control"
          placeholder="تاريخ البداية.."
        />
      </div>
      <div class="col-md-4">
        <select class="form-select">
          <option selected>اختر الحالة</option>
          <option value="1">موافقة</option>
          <option value="2">قيد الانتظار</option>
          <option value="3">مرفوض</option>
        </select>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <!-- Table -->
    <table>
      <thead>
        <tr>
          <th>اسم العميل</th>
          <th>رقم الهاتف</th>
          <th>كود التورير</th>
          <th>تاريخ الطلب</th>
          <th>الاجمالي</th>
          <th>العموله</th>
          <th>الحالة</th>
          <th>السبب</th>
          <th>رساله</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>محمود علي</td>
          <td>0116667777</td>
          <td>#ghd788</td>
          <td>20/10/2021</td>
          <td>350</td>
          <td>70</td>
          <td>ملغي قبل الشحن</td>
          <td>-</td>
          <td>معلق</td>
        </tr>
      </tbody>
    </table>

    <!-- Progress Bar -->
    <div class="progress-bar">
      <div class="step pending">
        <div class="icon">⏳</div>
        <div class="label">تم تسليم المعوله</div>
      </div>

      <div class="step completed">
        <div class="icon">↩️</div>
        <div class="label">مرتجع</div>
      </div>

      <div class="step completed">
        <div class="icon">✔️</div>
        <div class="label">تم التسليم</div>
      </div>

      <div class="step completed">
        <div class="icon">📦</div>
        <div class="label">تم الشحن</div>
      </div>

      <div class="step completed">
        <div class="icon">⏳</div>
        <div class="label">ملفي قبل الشحن</div>
      </div>

      <div class="step completed">
        <div class="icon">📄</div>
        <div class="label">تم التأكيد</div>
      </div>

      <div class="step completed">
        <div class="icon">📄</div>
        <div class="label">معلق</div>
        <!-- Pending label at the top -->
        <div class="pending-status">معلق</div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <!-- Buttons -->
      <div class="buttons">
        <button class="exchange btn px-5">طلب استبدال</button>
        <button class="refund btn px-5">طلب استرجاع</button>
      </div>

      <!-- Policy Text -->
      <div class="policy">
        <button class="btn font-weight-bold">سياسة الاستبدال والاسترجاع</button>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <!-- Table -->
    <table>
      <tbody>
        <tr>
          <td>محمود علي</td>
          <td>0116667777</td>
          <td>#ghd788</td>
          <td>20/10/2021</td>
          <td>350</td>
          <td>70</td>
          <td>ملغي قبل الشحن</td>
          <td>-</td>
          <td>معلق</td>
        </tr>
      </tbody>
    </table>

    <!-- Progress Bar -->
    <div class="progress-bar">
      <div class="step pending">
        <div class="icon">⏳</div>
        <div class="label">تم تسليم المعوله</div>
      </div>

      <div class="step completed">
        <div class="icon">↩️</div>
        <div class="label">مرتجع</div>
      </div>

      <div class="step completed">
        <div class="icon">✔️</div>
        <div class="label">تم التسليم</div>
      </div>

      <div class="step completed">
        <div class="icon">📦</div>
        <div class="label">تم الشحن</div>
      </div>

      <div class="step completed">
        <div class="icon">⏳</div>
        <div class="label">ملفي قبل الشحن</div>
      </div>

      <div class="step completed">
        <div class="icon">📄</div>
        <div class="label">تم التأكيد</div>
      </div>

      <div class="step completed">
        <div class="icon">📄</div>
        <div class="label">معلق</div>
        <!-- Pending label at the top -->
        <div class="pending-status">معلق</div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <!-- Policy Text -->
      <div class="policy">
        <button class="btn font-weight-bold">سياسة الاستبدال والاسترجاع</button>
      </div>
      <!-- Buttons -->
      <div class="buttons">
        <button class="exchange btn px-5">طلب استبدال</button>
        <button class="refund btn px-5">طلب استرجاع</button>
      </div>
    </div>
  </div>
  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
import MyHeader from "@/components/MyHeader.vue";
export default {
  name: "_Orders",
  components: {
    MyNavbar,
    MyFooter,
    MyHeader,
  },
  data() {
    return {
      isNavVisible: true,
      totalOrders: [],
    };
  },
  methods: {
    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
    },
    async getTotalOrders() {
      try {
        const token = localStorage.getItem("authToken"); // Assuming you store your auth token in localStorage

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/affiliate/sights",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          // Handle the case where the response status code is not 2xx
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 0) {
          console.error("Unauthenticated: ", data.msg);
        } else if (data && Array.isArray(data.data)) {
          this.totalOrders = data.data;
        } else {
          console.error("Unexpected response structure", data);
        }
      } catch (error) {
        console.error("Failed to fetch total orders", error);
      }
    },
  },
  async mounted() {
    this.getTotalOrders();
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.navbar .nav-item {
  font-weight: bold;
}
.listNav {
  padding: 10px;
  background-color: #0c2d58;
  text-align: right;
  width: 40%;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: none;
}
.dashboard-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.form-control {
  border-radius: 5px;
}
.search-btn {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.search-btn:hover {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.inputSearsh {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
}
.divCont {
  height: 200px;
  margin: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.divCont h4:after {
  content: "";
  background-color: #fd6735;
  width: 60px;
  height: 4px;
  display: block;
  margin: 25px auto;
}
.input-group {
  width: 250px;
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    right: 0px;
    top: 27%;
    width: 40%;
    background-color: #0c2d58 !important;
  }
  .navbar .nav-item {
    text-align: right;
    width: 100%;
    color: #fdfdfd;
    margin: 5px 0;
    padding: 10px;
  }
  .listNav {
    display: block;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  text-align: center;
  padding: 10px;
}

th {
  background-color: #f4f4f4;
  color: #ff6f3d;
}

td {
  background-color: #fff;
  color: #000;
}

.progress-bar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  min-height: 11rem;
  margin: 50px auto;
  position: relative;
}

.progress-bar::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ddd;
  z-index: 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  top: 10px;
}

.step .icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
}

.step.completed .icon {
  background-color: #ffc107;
}

.step.pending .icon {
  background-color: #001e3c;
}

.step .label {
  margin-top: 10px;
  font-size: 14px;
  color: #000;
}

.step.pending .label {
  color: red;
}
/* Special Label for the Pending State at the top */
.pending-status {
  position: absolute;
  top: -40px;
  background-color: #001e3c;
  color: white;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.step.pending .sub-label {
  color: #ff6347; /* Orange-red color for the sub-label (معلق) */
}
.buttons {
  text-align: center;
  margin: 20px 0;
}

.buttons button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.buttons .exchange {
  background-color: #ff6f3d;
  color: #fff;
}

.buttons .refund {
  background-color: #ddd;
  color: #666;
  cursor: not-allowed;
}

.policy {
  text-align: center;
  color: #ff6f3d;
  margin-top: 20px;
}
</style>
