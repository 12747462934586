<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="lofoot">
            <div class="footLogo">
              <img src="../assets/LOGO foot.png" alt="not found" />
            </div>
            <p>
              اكبر واول منصه فى مصر بتقدم خدمات متكامله لكل من يحلم ببناء كيان
              تجارى الكترونى
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <h5 class="px-2">الصفحات</h5>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link active"
                aria-current="page"
                @click="$router.push({ name: 'My_Home' })"
                >الرئيسية</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$router.push({ name: 'A_bout' })"
                >من نحن</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$router.push({ name: 'AboutSystem' })"
                >شرح السيستم</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$router.push({ name: 'HowAdd' })"
                >كيف تنضم؟</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <h5 class="px-2">التواصل</h5>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link active"
                aria-current="page"
                @click="$router.push({ name: 'My_Home' })"
                >تواصل معنا</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$router.push({ name: 'A_bout' })"
                >الدعم والشكاوي</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <h5 class="px-2">المواقع</h5>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link active"
                aria-current="page"
                @click="$router.push({ name: 'My_Home' })"
                >تابعنا عبر فيسبوك
                <font-awesome-icon
                  :icon="['fas', 'facebook-f']"
                  class="text-white"
              /></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$router.push({ name: 'A_bout' })"
                ><font-awesome-icon :icon="['fas', 'x']" /> تابعنا عبر تويتر
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="$router.push({ name: 'A_bout' })"
                >انضم الينا في تليجرام
                <font-awesome-icon :icon="['fas', 'telegram']"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "TheFoot",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
footer {
  background-color: #0c2d58;
  padding: 20px 10px;
  width: 100%;
  color: #fff;
  margin-top: 100px;
}
.footLogo {
  width: 100px;
  height: auto;
}
.lofoot p {
  color: #fff;
  line-height: 2.4;
  text-align: justify;
  margin: 10px 0px;
}
footer h5 {
  text-align: justify;
  margin-right: 30px;
}
ul li {
  text-align: justify;
}
footer h5 {
  font-weight: bold;
}
</style>
