<template>
  <TheNav />
  <div class="secForgetpass pt-5 mt-5 text-center m-auto">
    <div class="imgForgetpass">
      <img
        class="w-100 h-100"
        src="../assets/Done-rafiki (1).svg"
        alt="not found"
      />
    </div>
    <h3 class="py-3 text-center">تم تغيير كلمة المرور بنجاح</h3>
    <p class="py-1 text-center" style="color: #565656">
      تم تغيير كلمة مرورك بنجاح نشكرك علي وقتك في تحقيق الأمان الذي نسعي عليه
    </p>
    <div class="inputForget w-50 m-auto mt-2">
      <button
        class="btn btn-primary px-3 w-50 my-3"
        @click="$router.push({ name: 'Home_Page' })"
      >
        الذهاب الي صفحتك
      </button>
    </div>
  </div>
</template>
<script>
import TheNav from "@/components/TheNav";
export default {
  name: "ChangePassword",
  components: {
    TheNav,
  },
};
</script>
<style scoped>
.imgForgetpass {
  width: 280px;
  height: 250px;
  margin: auto;
}
.secForgetpass p {
  font-weight: bold;
}
@media (max-width: 720px) {
  input.form-control {
    width: 80% !important;
  }
  .inputForget {
    width: 80% !important;
  }
}
</style>
