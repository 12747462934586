import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fontsource/roboto";
import "jquery";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/main.scss";
import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
import { createI18n } from "vue-i18n";

const messages = {
  en: {
    welcome: "Welcome",
  },
  fr: {
    welcome: "Bienvenue",
  },
};

const i18n = createI18n({
  locale: "en",
  messages,
});

library.add(fas);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(i18n)
  .use(VueTelInput)
  .use(store)
  .use(router)
  .mount("#app");
