<template>
  <MyNavbar />
  <div class="container">
    <div class="form-container">
      <!-- Form Inputs -->
      <div class="form-section">
        <label for="first-name">الاسم الاول</label>
        <input type="text" id="first-name" placeholder="ياسين" />

        <label for="email">البريد</label>
        <input
          type="email"
          id="email"
          placeholder="Yassin.khaled@gmail.com"
          disabled
        />

        <label for="province">المحافظة</label>
        <select id="province">
          <option>الجيزة</option>
          <option>القاهرة</option>
        </select>

        <label for="address">العنوان</label>
        <input type="text" id="address" placeholder="شارع المالك، الجيزة" />

        <label for="link">رابط صفحة الفيسبوك</label>
        <input
          type="text"
          id="link"
          placeholder="www.facebook.com/xxxxxxxxxx"
        />
      </div>

      <div class="form-section">
        <label for="last-name">الاسم الاخير</label>
        <input type="text" id="last-name" placeholder="خالد" />

        <label for="phone-number">رقم الهاتف</label>
        <input
          type="text"
          id="phone-number"
          placeholder="01123457888"
          disabled
        />

        <label for="city">المدينة</label>
        <select id="city">
          <option>الجيزة</option>
          <option>القاهرة</option>
        </select>

        <label for="whatsapp-number">رقم الواتساب</label>
        <input
          type="text"
          id="whatsapp-number"
          placeholder="اكتب رقم الواتساب الخاص بك"
        />

        <label for="advertising-method">طريقة ترويج المنتجات</label>
        <select id="advertising-method">
          <option>Facebook</option>
          <option>Instagram</option>
        </select>

        <label for="password">كلمة المرور</label>
        <div class="password-container">
          <input type="password" id="password" placeholder="********" />
          <img
            src="eye-icon.png"
            alt="Toggle Visibility"
            class="toggle-password"
          />
        </div>
      </div>

      <!-- Profile Image Section -->
      <div class="profile-container">
        <img src="profile-placeholder.png" alt="Profile Image" />
        <p>تعديل البيانات</p>
      </div>
    </div>
  </div>

  <!-- Form Footer -->
  <div class="form-footer">
    <button>حفظ التغييرات</button>
  </div>
  <MyFooter />
</template>
<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
export default {
  name: "_myprofile",
  components: {
    MyNavbar,
    MyFooter,
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.form-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.form-section {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.form-section label {
  margin-bottom: 5px;
  color: #333;
  font-weight: bold;
}

.form-section input,
.form-section select {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.form-section input[disabled] {
  background-color: #f5f5f5;
  color: #888;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
}

.profile-container img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-container button {
  padding: 8px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  flex-grow: 1;
}

.password-container .toggle-password {
  margin-left: -40px;
  cursor: pointer;
}

.form-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.form-footer button {
  padding: 10px 20px;
  background-color: #1c3b91;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.help-btn {
  position: absolute;
  bottom: 30px;
  right: 50px;
  padding: 10px 20px;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
</style>
