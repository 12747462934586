<template>
  <MyNavbar />
  <div class="listNav" @click="toggleNav">
    <div class="d-flex">
      <font-awesome-icon :icon="['fas', 'list']" />
      <span class="d-block px-2">القائمة</span>
    </div>
  </div>
  <MyHeader />
  <!-- <div class="navbar bg-light" v-if="isNavVisible">
      <div class="nav-item">
        <router-link class="nav-link">احصائيات</router-link>
      </div>
      <div class="nav-item">
        <router-link class="nav-link">احصائيات متقدمة</router-link>
      </div>
      <div class="nav-item">
        <router-link class="nav-link">الطلبات</router-link>
      </div>
      <div class="nav-item">
        <router-link class="nav-link">طلبات تحويل العمولة</router-link>
      </div>
      <div class="nav-item">
        <router-link class="nav-link">الحسابات</router-link>
      </div>
      <div class="nav-item">
        <router-link class="nav-link">إضافة مودريتور</router-link>
      </div>
      <div class="nav-item">
        <span class="nav-icon"
          ><font-awesome-icon :icon="['fas', 'download']" class="mx-2"
        /></span>
        تقرر اكسيل
      </div>
    </div> -->
  <div class="container mt-5">
    <div class="row mb-3">
      <div class="col-12 text-end">
        <h4 class="dashboard-title">لوحة التحكم</h4>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-md-4">
        <div class="input-group">
          <input
            type="text"
            class="form-control inputSearsh"
            placeholder="بحث برقم المنتج.."
            aria-label="بحث"
          />
          <button class="btn search-btn">بحث</button>
        </div>
      </div>
    </div>
    <div class="row g-3 my-3">
      <div class="col-md-4">
        <input
          type="text"
          onfocus="(this.type = 'date')"
          class="form-control"
          placeholder="تاريخ النهاية.."
        />
      </div>
      <div class="col-md-4">
        <input
          type="text"
          onfocus="(this.type = 'date')"
          class="form-control"
          placeholder="تاريخ البداية.."
        />
      </div>
      <div class="col-md-4">
        <select class="form-select">
          <option selected>اختر الحالة</option>
          <option value="1">موافقة</option>
          <option value="2">قيد الانتظار</option>
          <option value="3">مرفوض</option>
        </select>
      </div>
    </div>
  </div>
  <div class="container my-5">
    <div class="row">
      <div
        class="col-md-3"
        v-for="(ordcount, index) in totalOrders"
        :key="index"
      >
        <div class="divCont">
          <h4 class="pt-5 text-center">{{ ordcount.st_ar }}</h4>
          <h5 class="text-center">{{ ordcount.count }}</h5>
        </div>
      </div>
    </div>
  </div>
  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
import MyHeader from "@/components/MyHeader.vue";
export default {
  name: "AdvancedStatistics",
  components: {
    MyNavbar,
    MyFooter,
    MyHeader,
  },
  data() {
    return {
      isNavVisible: true,
      totalOrders: [],
    };
  },
  methods: {
    toggleNav() {
      this.isNavVisible = !this.isNavVisible;
    },
    async getTotalOrders() {
      try {
        const token = localStorage.getItem("authToken"); // Assuming you store your auth token in localStorage

        if (!token) {
          console.error("No auth token found. Please log in.");
          return;
        }

        const response = await fetch(
          "https://ecomerg.za3bot.com/api/affiliate/sights",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          // Handle the case where the response status code is not 2xx
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 0) {
          console.error("Unauthenticated: ", data.msg);
        } else if (data && Array.isArray(data.data)) {
          this.totalOrders = data.data;
        } else {
          console.error("Unexpected response structure", data);
        }
      } catch (error) {
        console.error("Failed to fetch total orders", error);
      }
    },
  },
  async mounted() {
    this.getTotalOrders();
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.navbar .nav-item {
  font-weight: bold;
}
.listNav {
  padding: 10px;
  background-color: #0c2d58;
  text-align: right;
  width: 40%;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: none;
}
.dashboard-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.form-control {
  border-radius: 5px;
}
.search-btn {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.search-btn:hover {
  background-color: #0c2d58;
  color: white;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
}
.inputSearsh {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
}
.divCont {
  height: 200px;
  margin: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
.divCont h4:after {
  content: "";
  background-color: #fd6735;
  width: 60px;
  height: 4px;
  display: block;
  margin: 25px auto;
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    right: 0px;
    top: 27%;
    width: 40%;
    background-color: #0c2d58 !important;
  }
  .navbar .nav-item {
    text-align: right;
    width: 100%;
    color: #fdfdfd;
    margin: 5px 0;
    padding: 10px;
  }
  .listNav {
    display: block;
  }
}
</style>
