<template>
  <MyNavbar />
  <div class="listNav">
    <div class="d-flex">
      <font-awesome-icon :icon="['fas', 'list']" />
      <span class="d-block px-2">القائمة</span>
    </div>
  </div>
  <MyHeader />
  <div class="container pt-5">
    <h2>الحسابات</h2>

    <!-- Tab Header -->
    <div class="tabs">
      <div
        :class="{ active: activeTab === 'paymentMethod' }"
        @click="activeTab = 'paymentMethod'"
      >
        طريقة الدفع
      </div>
      <div
        :class="{ active: activeTab === 'paymentMeth' }"
        @click="activeTab = 'paymentMeth'"
      >
        المدفوعات
      </div>
      <div
        :class="{ active: activeTab === 'feesDue' }"
        @click="activeTab = 'feesDue'"
      >
        عمولة اوردات مستحقة الدفع
      </div>
      <div
        :class="{ active: activeTab === 'feesPending' }"
        @click="activeTab = 'feesPending'"
      >
        عمولة اوردات تحت التحصيل والمراجعة
      </div>
    </div>

    <!-- Tab Content -->
    <div class="tab-content" v-if="activeTab === 'paymentMethod'">
      <h3>طريقة الدفع</h3>
      <div class="checkbox-group py-2">
        <label
          ><input type="checkbox" v-model="paymentMethods.InstaPay" />
          InstaPay</label
        >
        <label
          ><input type="checkbox" v-model="paymentMethods.PayPal" />
          PayPal</label
        >
        <label
          ><input
            type="checkbox"
            v-model="paymentMethods.VodafoneCash"
            checked
          />
          Vodafone Cash</label
        >
      </div>
      <div class="input-group">
        <label style="display: block" for="phone-number">رقم الهاتف</label>
        <input type="text" id="phone-number" v-model="phoneNumber" />
      </div>
      <button class="px-5" @click="submitPaymentMethod">حفظ</button>
    </div>

    <div class="tab-content" v-if="activeTab === 'paymentMeth'">
      <table class="">
        <thead>
          <tr>
            <th>تفاصيل اكثر</th>
            <th>صورة التحويل</th>
            <th>اجمالي المبلغ</th>
            <th>تاريخ التحويل</th>
            <th>طريقة التحويل</th>
            <th>القائم بالتحويل</th>
            <th>كود العملية</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1257</td>
            <td>سيد احمد</td>

            <td>Vodafone Cash</td>

            <td>08/03/2024</td>

            <td>650</td>

            <td class="icons">
              <img src="image-icon.png" alt="Image" class="image-icon" />
            </td>
            <td class="view-icon">
              <img src="eye-icon.png" alt="View" class="eye-icon" />
            </td>
          </tr>
          <tr>
            <td>1257</td>
            <td>سيد احمد</td>

            <td>Vodafone Cash</td>

            <td>08/03/2024</td>

            <td>650</td>

            <td class="icons">
              <img src="image-icon.png" alt="Image" class="image-icon" />
            </td>
            <td class="view-icon">
              <img src="eye-icon.png" alt="View" class="eye-icon" />
            </td>
          </tr>
          <tr>
            <td>1257</td>
            <td>سيد احمد</td>

            <td>Vodafone Cash</td>

            <td>08/03/2024</td>

            <td>650</td>

            <td class="icons">
              <img src="image-icon.png" alt="Image" class="image-icon" />
            </td>
            <td class="view-icon">
              <img src="eye-icon.png" alt="View" class="eye-icon" />
            </td>
          </tr>
          <tr>
            <td>1257</td>
            <td>سيد احمد</td>

            <td>Vodafone Cash</td>

            <td>08/03/2024</td>

            <td>650</td>

            <td class="icons">
              <img src="image-icon.png" alt="Image" class="image-icon" />
            </td>
            <td class="view-icon">
              <img src="eye-icon.png" alt="View" class="eye-icon" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="tab-content" v-if="activeTab === 'feesDue'">
      <div class="headcontain">
        <table>
          <thead>
            <tr>
              <th>رقم الوردر</th>
              <th>اسم العميل</th>
              <th>عمولة الوردر</th>
              <th>حالة الوردر</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>88</td>
              <td>محمود احمد</td>
              <td>88</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>50</td>
              <td>هاني عادي</td>
              <td>50</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>134</td>
              <td>معلا علي</td>
              <td>134</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>50</td>
              <td>عمر احمد</td>
              <td>50</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>650</td>
              <td>سيد احمد</td>
              <td>650</td>
              <td>تم التسليم</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tab-content" v-if="activeTab === 'feesPending'">
      <div class="headcontain">
        <table>
          <thead>
            <tr>
              <th>رقم الوردر</th>
              <th>اسم العميل</th>
              <th>عمولة الوردر</th>
              <th>حالة الوردر</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>88</td>
              <td>محمود احمد</td>
              <td>88</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>50</td>
              <td>هاني عادي</td>
              <td>50</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>134</td>
              <td>معلا علي</td>
              <td>134</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>50</td>
              <td>عمر احمد</td>
              <td>50</td>
              <td>تم التسليم</td>
            </tr>
            <tr>
              <td>650</td>
              <td>سيد احمد</td>
              <td>650</td>
              <td>تم التسليم</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <MyFooter />
</template>

<script>
import MyNavbar from "@/components/Navbar.vue";
import MyFooter from "@/components/footer.vue";
import MyHeader from "@/components/MyHeader.vue";
export default {
  name: "_Accounts",
  components: {
    MyNavbar,
    MyFooter,
    MyHeader,
  },
  data() {
    return {
      isNavVisible: true,
      activeTab: "paymentMethod", // Default tab
      paymentMethods: {
        InstaPay: false,
        PayPal: false,
        VodafoneCash: true,
      },
      phoneNumber: "",
    };
  },
  methods: {},
  async mounted() {},
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.navbar .nav-item {
  font-weight: bold;
}

.container {
  width: 90%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: right;
  color: #e94e0f;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
}

.tabs div {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabs .active {
  color: #e94e0f;
  border-bottom: 2px solid #e94e0f;
}

.tab-content {
  padding: 20px;
  margin-top: 20px;
}

.checkbox-group {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.input-group {
  display: block;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.input-group label {
  margin-left: 10px;
  display: block;
  font-weight: bold;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 250px;
  border-radius: 7px !important;
}

button {
  padding: 10px 20px;
  background-color: #001f4d;
  color: white;
  border: none;
  border-radius: 7px !important;
  cursor: pointer;
}

button:hover {
  background-color: #003080;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.eye-icon,
.image-icon {
  width: 20px;
  height: 20px;
}

.view-icon {
  cursor: pointer;
}

td .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.headcontain table th {
  color: #e94e0f;
}
</style>
