<template>
  <div class="navbar bg-light">
    <div class="nav-item">
      <router-link class="nav-link" to="/dashboard">احصائيات</router-link>
    </div>
    <div class="nav-item">
      <router-link class="nav-link" to="/AdvancedStatistics"
        >احصائيات متقدمة</router-link
      >
    </div>
    <div class="nav-item">
      <router-link class="nav-link" to="/Orders">الطلبات</router-link>
    </div>
    <div class="nav-item">
      <router-link class="nav-link" to="/CommissionTransferRequests"
        >طلبات تحويل العمولة</router-link
      >
    </div>
    <div class="nav-item">
      <router-link class="nav-link" to="/Accounts">الحسابات</router-link>
    </div>
    <div class="nav-item">
      <router-link class="nav-link" to="/AddModerator"
        >إضافة مودريتور</router-link
      >
    </div>
    <div class="nav-item">
      <span class="nav-icon"
        ><font-awesome-icon :icon="['fas', 'download']" class="mx-2"
      /></span>
      تقرر اكسيل
    </div>
  </div>
</template>
<style scoped>
.router-link-active {
  font-weight: bold;
  color: #fd6735 !important;
}
</style>
