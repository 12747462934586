<template>
  <TheNav />
  <h2>about</h2>
</template>
<script>
import TheNav from "@/components/TheNav";
export default {
  name: "A_bout",
  components: {
    TheNav,
  },
};
</script>
