<template>
  <div
    class="_notfound d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div>
      <h2 class="pt-5">Not Found</h2>
      <button class="btn btn-primary">
        <router-link :to="{ name: 'My_Home' }">Back to home</router-link>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: `"NotFounds"`,
};
</script>
