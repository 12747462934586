<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"
        ><img src="../assets/logo.png" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="nav-link active"
              aria-current="page"
              @click="$router.push({ name: 'My_Home' })"
              >الرئيسية</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="$router.push({ name: 'A_bout' })"
              >من نحن</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="$router.push({ name: 'AboutSystem' })"
              >شرح السيستم</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="$router.push({ name: 'HowAdd' })"
              >كيف تنضم؟</a
            >
          </li>
        </ul>
        <form class="d-flex">
          <button class="btn" @click="$router.push({ name: 'log_in' })">
            تسجيل دخول
          </button>
          <button
            class="btn btn-primary px-4 mx-2"
            @click="$router.push({ name: '_register' })"
          >
            سجل الآن
          </button>
          <select name="" id="" class="btn">
            <option value="1" selected>العربيه</option>
            <option value="2">English</option>
          </select>
        </form>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "TheNav",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap");

.navbar-brand {
  width: 100px;
  height: 60px;
}
.navbar-brand img {
  width: 100%;
  height: 100%;
}
.btn-primary {
  background: #0c2d58 !important;
  border: 1px solid #0c2d58 !important;
}
.navbar {
  background: #fff !important;
  box-shadow: 0px 3px 6px #00000029;
}
a {
  cursor: pointer;
}
@media (max-width: 720px) {
  form {
    display: block !important;
  }
  .btn {
    display: block;
    margin: 0px 10px !important;
  }
  .nav-item {
    text-align: right;
  }
}
</style>
