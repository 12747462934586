<template>
  <TheNav />
  <h2>How Add</h2>
</template>
<script>
import TheNav from "@/components/TheNav";
export default {
  name: "HowAdd",
  components: {
    TheNav,
  },
};
</script>
