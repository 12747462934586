<template>
  <TheNav />
  <div class="container pt-5 mt-5">
    <div class="row">
      <div class="col-md-6">
        <h3 class="colorBlue py-3">سجل حسابك الآن</h3>
        <form @submit.prevent="registerData">
          <div class="mb-1">
            <label for="name" class="form-label d-block">الاسم</label>
            <input
              type="text"
              v-model="formData.name"
              class="form-control"
              id="name"
              placeholder="اكتب الاسم بالكامل"
              required
            />
          </div>
          <div class="mb-1">
            <label for="phone" class="form-label d-block">رقم الهاتف</label>
            <input
              type="text"
              class="form-control"
              :placeholder="customPlaceholder"
              v-model="formData.phone"
              id="phone"
              required
            />
            <vue-tel-input></vue-tel-input>
          </div>
          <div class="mb-1">
            <label for="email" class="form-label d-block">الايميل</label>
            <input
              type="email"
              v-model="formData.email"
              class="form-control"
              id="email"
              placeholder="اكتب الايميل الخاص بك"
              required
            />
          </div>
          <div class="mb-1">
            <label for="password" class="form-label d-block">كلمة المرور</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="formData.password"
              placeholder="اكتب كلمة المرور"
              required
            />
          </div>
          <div class="mb-1">
            <label for="passwordconfirmation" class="form-label d-block"
              >كلمة المرور للتأكيد</label
            >
            <input
              type="password"
              v-model="formData.passwordconfirmation"
              class="form-control"
              id="passwordconfirmation"
              placeholder="اكتب كلمة المرور للتأكيد"
              required
            />
          </div>
          <div class="mb-1 form-chec textRight">
            <input
              type="checkbox"
              class="form-check-input"
              id="agreeTerms"
              v-model="formData.terms_conditions"
              required
            />
            <label class="form-check-label mx-2" for="agreeTerms">
              اوافق على الموقع الخاص
              <a class="colorBlue">بالشروط والأحكام</a>
            </label>
          </div>
          <button type="submit" class="btn btn-primary w-75">
            تسجيل الدخول
          </button>
          <p class="font-weight-bold py-3">
            لديك حساب بالفعل؟
            <a class="colorOrange" @click="$router.push({ name: 'log_in' })">
              تسجيل الدخول
            </a>
          </p>
        </form>
      </div>
      <div class="col-md-6">
        <div class="bgImgLogin w-75 me-auto pt-5">
          <img
            src="../assets/Mobile login.png"
            class="w-100 h-100"
            alt="Login Illustration"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav";
import VueTelInput from "vue-tel-input"; // Make sure you have this installed and imported

export default {
  name: "_register",
  components: {
    TheNav,
    VueTelInput, // Register the component here
  },
  data() {
    return {
      customPlaceholder: "ادخل رقم الهاتف",
      formData: {
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordconfirmation: "",
        terms_conditions: false, // This should be a boolean
        device_token: "",
      },
      response: null,
      error: null,
    };
  },
  methods: {
    async registerData() {
      if (this.formData.password !== this.formData.passwordconfirmation) {
        this.error = "كلمات المرور غير متطابقة";
        return;
      }

      try {
        // Prepare the data to be sent in the request
        const payload = {
          name: this.formData.name,
          phone: this.formData.phone,
          email: this.formData.email,
          password: this.formData.password,
          password_confirmation: this.formData.passwordconfirmation,
          terms_conditions: this.formData.terms_conditions ? 1 : 0, // Send as integer
          device_token: this.formData.device_token,
        };

        // Make the POST request
        const response = await fetch(
          "https://ecomerg.za3bot.com/api/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        // Check if the response is OK (status code 2xx)
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || `Error: ${response.statusText}`);
        }

        // Parse the response data
        const data = await response.json();
        this.response = data;

        // Store form data in local storage
        localStorage.setItem("userData", JSON.stringify(this.formData));

        // Redirect to login page after successful registration
        this.$router.push({ name: "log_in" });
      } catch (error) {
        // Handle error, e.g., show an error message
        this.error = error.message;
        console.error("Registration failed:", error);
      }
    },
  },
  mounted() {
    // Retrieve form data from local storage
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      this.formData = JSON.parse(storedData);
    }
  },
};
</script>

<style scoped>
label {
  text-align: right;
  font-weight: bold;
}

a {
  cursor: pointer;
}
</style>
